import { DependencyContainer } from '../../../DependencyContainer';
import { AxiosResponse } from 'axios';
import { ContentUrls } from '../../../config/urls';
import {
  Page,
  ScenariosFields,
  StaffFields,
  WordpressContent,
  WordpressLevel,
  Post,
} from '../types';
import { HttpClient } from '../../../HttpClient';

export default class ContentClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async fetchScenarios(
    slug?: string,
  ): Promise<Array<WordpressContent<ScenariosFields>>> {
    const response = await this.get<Array<WordpressContent<ScenariosFields>>>(
      ContentUrls.getUrlForScenarios(slug),
    );
    return response.data;
  }

  async fetchMedia(mediaId: number): Promise<AxiosResponse<any>> {
    const response = await this.get<any>(ContentUrls.getUrlForMedia(mediaId));
    return response;
  }

  async fetchStaff(
    id?: number,
  ): Promise<AxiosResponse<Array<WordpressContent<StaffFields>>>> {
    const response = await this.get<Array<WordpressContent<StaffFields>>>(
      ContentUrls.getUrlForStaff(id),
    );
    return response;
  }

  async fetchPage(
    slug: string,
  ): Promise<AxiosResponse<Array<WordpressContent<Page>>>> {
    const response = await this.get<Array<WordpressContent<Page>>>(
      ContentUrls.getUrlForPage(slug),
    );
    return response;
  }

  async fetchLevels(
    slug?: string,
  ): Promise<AxiosResponse<Array<WordpressContent<WordpressLevel>>>> {
    const response = await this.get<Array<WordpressContent<WordpressLevel>>>(
      ContentUrls.getUrlForLevel(slug),
    );
    return response;
  }

  async fetchPost(
    slug?: string,
  ): Promise<AxiosResponse<Array<WordpressContent<Post>>>> {
    const response = await this.get<Array<WordpressContent<Post>>>(
      ContentUrls.getUrlForPost(slug),
    );
    return response;
  }
}

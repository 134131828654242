import { DependencyContainer } from '../../../DependencyContainer';
import {
  Feedback,
  Report,
  ReportPlayerAssessment,
  ReportTimeLineEvent,
} from '../types';

export default class ReportsService {
  constructor(private readonly factory: DependencyContainer) {}

  async getReports(token: string, userEmail?: string): Promise<Report[]> {
    let response;
    if (userEmail) {
      response = await this.factory.reportsClient.fetchUnapprovedReports(
        userEmail,
        token,
      );
    } else {
      response = await this.factory.reportsClient.fetchReports(token);
    }
    return response.data.map((item) => ({
      ...item,
      session_start: new Date(item.session_start),
    }));
  }

  async getReport(
    id: number,
    token: string,
    userEmail?: string,
  ): Promise<Report> {
    let response;
    if (userEmail) {
      response = await this.factory.reportsClient.fetchUnapprovedReport(
        userEmail,
        id,
        token,
      );
    } else {
      response = await this.factory.reportsClient.fetchReport(id, token);
    }
    return {
      ...response.data,
      session_start: new Date(response.data.session_start),
    };
  }

  async getTimeLineEvents(
    reportId: number,
    token: string,
    userEmail?: string,
  ): Promise<ReportTimeLineEvent[]> {
    let response;
    if (userEmail) {
      response =
        await this.factory.reportsClient.fetchTimeLineEventsForUnapprovedReport(
          userEmail,
          reportId,
          token,
        );
    } else {
      response = await this.factory.reportsClient.fetchTimeLineEvents(
        reportId,
        token,
      );
    }
    return response.data.map((event: ReportTimeLineEvent) => ({
      ...event,
      time: new Date(event.time),
    }));
  }

  async getPlayerAssessment(
    reportId: number,
    token: string,
    userEmail?: string,
  ): Promise<ReportPlayerAssessment> {
    let response;
    if (userEmail) {
      response =
        await this.factory.reportsClient.fetchPlayerAssessmentForUnapprovedReport(
          userEmail,
          reportId,
          token,
        );
    } else {
      response = await this.factory.reportsClient.fetchPlayerAssessment(
        reportId,
        token,
      );
    }
    return response.data;
  }

  async getPlayerFeedback(
    reportId: number,
    token: string,
    userEmail?: string,
  ): Promise<Feedback[]> {
    let response;
    if (userEmail) {
      response =
        await this.factory.reportsClient.fetchPlayerFeedbackForUnapprovedReport(
          userEmail,
          reportId,
          token,
        );
    } else {
      response = await this.factory.reportsClient.fetchPlayerFeedback(
        reportId,
        token,
      );
    }

    return response.data;
  }

  async toggleReport(
    reportId: number,
    token: string,
    userEmail: string,
  ): Promise<void> {
    await this.factory.reportsClient.toggleReport(userEmail, reportId, token);
  }
}

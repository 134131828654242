import React, { FunctionComponent } from 'react';
import Button from '../../../../components/Button/Button';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useUser } from '../../../common/hooks/useUser';
import { gamePlayAtom } from '../../../../state';
import { useRecoilValue } from 'recoil';

const { scenariosService } = DependencyContainer.getInstance();

const GamePlayAborted: FunctionComponent = () => {
  const user = useUser();
  const gamePlay = useRecoilValue(gamePlayAtom);

  const startAgain = async () => {
    if (!gamePlay.scenario || !gamePlay.level) {
      return;
    }
    const level = parseInt(gamePlay.level);
    await scenariosService.setPlayer(user, gamePlay.scenario, level);
  };

  return (
    <div className="GamePlay">
      <h2>We are sorry</h2>
      <p>Your game has been aborted</p>
      <Button size="small" onClick={() => startAgain()}>
        Start again
      </Button>
    </div>
  );
};

export default GamePlayAborted;

import React, { FunctionComponent } from 'react';
import { Scenario, ScenarioIntroduction } from '../types';
import OnlineBoutiqueIntroduction from './introductions/online-boutique/OnlineBoutiqueIntroduction';

export type ScenarioIntroductionRendererProps = {
  scenario: Scenario;
  step: number;
  hideControls?: boolean;
};

export const mapScenarioToIntroduction: Record<
  string,
  FunctionComponent<ScenarioIntroduction>
> = {
  'online-boutique': OnlineBoutiqueIntroduction,
};

const ScenarioIntroductionRenderer: FunctionComponent<
  ScenarioIntroductionRendererProps
> = (props) => {
  if (!props.scenario) {
    return null;
  }
  const Introduction = mapScenarioToIntroduction[props.scenario.id];
  if (!Introduction) {
    return null;
  }
  return (
    <Introduction
      scenario={props.scenario}
      step={props.step}
      hideControls={props.hideControls}
      onFinishIntroduction={() => {}}
    />
  );
};

export default ScenarioIntroductionRenderer;

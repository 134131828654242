import React, { FunctionComponent } from 'react';
import { Scenario, ScenarioIntroductionStepDefinition } from '../../types';
import useIntroductionPlayer from '../../hooks/useIntroductionPlayer';

export type IntroductionStepAction = {
  label: string;
  onClick: () => void;
  isAvailable: boolean;
};

export type IntroductionPlayerProps = {
  steps: ScenarioIntroductionStepDefinition[];
  scenario: Scenario;
  step?: number;
  onFinishIntroduction?: () => void;
  hideControls?: boolean;
};

export type IntroductionPlayerStep = {
  scenario: Scenario;
};

const IntroductionPlayer: FunctionComponent<IntroductionPlayerProps> = ({
  steps,
  scenario,
  hideControls,
  step = 1,
}) => {
  const { actions, renderedStep } = useIntroductionPlayer(
    steps,
    scenario,
    step,
  );

  return (
    <div className="IntroductionPlayer">
      {!hideControls && (
        <div className="IntroductionPlayer__header">
          <h2>Introduction to {scenario.title}</h2>
        </div>
      )}
      {renderedStep}
      {actions && !hideControls && (
        <div className="IntroductionPlayer__actions">{actions}</div>
      )}
    </div>
  );
};

export default IntroductionPlayer;

import { defaultApiUrl, defaultMqttPort, defaultSentryDsn } from './config';

export const getEnvironment = () => {
  return window.envs.REACT_APP_ENV;
};

export const getApiUrl = () => {
  return window.envs.REACT_APP_API_URL || defaultApiUrl;
};

export const getSentryDSN = () => {
  return window.envs.REACT_APP_SENTRY_DSN || defaultSentryDsn;
};


export const getMQTTPort = () => {
  return window.envs.MQTT_PORT || defaultMqttPort;
};

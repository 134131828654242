import React, { useContext } from 'react';
import classNames from 'classnames';
import DropdownContext from './DropdownContext';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';
import PendingContent from '../PendingContent/PendingContent';
import SpinnerLoader from '../SpinnerLoader/SpinerLoader';

export type DropdownWindowProps = {
  isContentLoading?: boolean;
};
export type DropdownWindowStyles = {
  root: string;
};

const DropdownWindow: AdjustableComponent<
  DropdownWindowProps,
  DropdownWindowStyles
> = (props) => {
  const context = useContext(DropdownContext);
  const loading = Boolean(props.isContentLoading);

  const classes = useClasses(
    {
      root: classNames('Dropdown__window', {
        'Dropdown__window--open': context.isOpen,
      }),
    },
    props.classes,
  );

  return (
    <div className={classes.root}>
      <PendingContent loader={SpinnerLoader} loading={loading}>
        {props.children}
      </PendingContent>
    </div>
  );
};

export default DropdownWindow;

const throttle = (fn: CallableFunction, wait: number) => {
  let lastCall = 0;
  return function () {
    if (Date.now() - lastCall > wait) {
      lastCall = Date.now();
      fn();
    }
  };
};

export default throttle;

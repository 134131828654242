import Button from "../../components/Button/Button"
import { AppRoutes } from "../../config/routes"
import { useRedirectToRoute } from '../common/hooks/useRedirectToRoute';
import logo from './../../assets/images/logo-min.png';

const ProxyErrorContainer = () => {
    const goToRoute = useRedirectToRoute();
    // const gamePlay = useRecoilValue(gamePlayAtom);
    return(
        <div className="proxy-error-conatiner">
            <img src={logo} alt="UptimeLabs" />
            <h1>Looks like your session isn't active...</h1>
            <p>'Select a level' to start a new drill</p>
            <Button variant="superprimary" onClick={() => goToRoute(AppRoutes.ScenarioLevels, { id: 'online-boutique' })}>Select a level</Button>
        </div>
    )
}

export default ProxyErrorContainer
import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

type TextLoaderProps = {
  visible: boolean;
};
type TextLoaderStyles = {
  root: string;
};
const TextLoader: AdjustableComponent<TextLoaderProps, TextLoaderStyles> = ({
  visible,
  classes,
}) => {
  const styles = useClasses(
    {
      root: `TextLoader`,
    },
    classes,
  );

  if (!visible) {
    return null;
  }

  return <span className={styles.root}>Loading</span>;
};
export default TextLoader;

import { FunctionComponent } from 'react';
import LobbyCard from './lobby';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { DependencyContainer } from '../../../../DependencyContainer';
import { Lobby } from './types';
import { Scenario } from '../../types';
import { WordpressContent, WordpressLevel } from '../../../content/types';
import { useBearerToken } from '../../../common/hooks/useBearerToken';
import { useKeycloak } from '@react-keycloak/web';
import { useUser } from '../../../common/hooks/useUser';
import { useFetchData } from '../../../common/hooks/useFetchData';
import {  ScenarioStatuses } from '../../types';
import LockedScenarioMessage from '../LockedScenarioMessage';
import { Helmet } from 'react-helmet';
import SpinnerLoader from '../../../../components/SpinnerLoader/SpinerLoader';
import PendingContent from '../../../../components/PendingContent/PendingContent';


const { contentService, scenariosService, lobbyService } =
  DependencyContainer.getInstance();

const JoinLobbyContainer: FunctionComponent = () => {
  const [lobby, setLobby] = useState<Lobby | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { lobbyOwner, lobbyScenario, lobbyLevel, lobbyUuid } = useParams();
  const { keycloak } = useKeycloak();
  const user = useUser();
  const token = useBearerToken();
  const [levelLoading, level, levelError] = useFetchData<
    WordpressContent<WordpressLevel>,
    Error
  >(
    () => {
      return contentService.getLevelById(Number(lobbyLevel));
    },
    undefined,
    [lobbyScenario, lobbyLevel],
    () => !lobbyLevel,
  );

  const [loading, scenario, error] = useFetchData<Scenario, Error>(() => {
    if (!lobbyScenario) {
      return Promise.reject();
    }
    return scenariosService.getScenario(lobbyScenario);
  });

  useEffect(() => {
    const findLobby = async () => {
      const lobby = await lobbyService.getLobbyByEmail(token!, lobbyOwner!);
      setLobby(lobby);
    };
      findLobby();
  }, [token]);


  if (scenario?.status === ScenarioStatuses.Locked) {
    return <LockedScenarioMessage />;
  }
  const renderTitle = (): string => {
    return `${scenario?.title} -  ${level?.title?.rendered}`;
  };

  return (
    <div className="ScenariosContainer">
      <Helmet>
        <title> UptimeLabs </title>
      </Helmet>
      {!loading && scenario && (
        <Helmet>
          <title>{renderTitle()} - UptimeLabs</title>
        </Helmet>
      )}
      <PendingContent
        loading={loading || levelLoading}
        isError={Boolean(error) || Boolean(levelError)}
        hideContent
        message={error?.message}
        loader={SpinnerLoader}
      >
        <div className="ScenariosContainer__scenario">
          <div className="ScenariosContainer__content">
            <h1 className="ScenariosContainer__title">{renderTitle()}</h1>
            {level?.content.rendered && (
              <div className="ScenariosContainer__levelIntroduction">
                <div
                  className="ScenariosContainer__description"
                  dangerouslySetInnerHTML={{
                    __html: level.content.rendered,
                  }}
                />
                {scenario && (
                  <LobbyCard
                    scenario={scenario}
                    level={level}
                    user={user}
                    keycloak={keycloak}
                    readyToPlay={false}
                    isJoiningExistingLobby={true}
                    joiningLobby={lobby}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </PendingContent>
    </div>
  );
};

export default JoinLobbyContainer;

import React, { FunctionComponent, useState } from 'react';
import Input, { InputStyles } from '../../../../components/Input/Input';
import { useRedirectToRoute } from '../../../common/hooks/useRedirectToRoute';
import { AppRoutes } from '../../../../config/routes';
import Button, { ButtonStyles } from '../../../../components/Button/Button';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  reportsAtom,
  reportsLoadingAtom,
  reportsSelectedUserEmailSelector,
} from '../../../../state';
import { Report } from '../../../reports/types';
import PendingContent, {
  PendingContentStyles,
} from '../../../../components/PendingContent/PendingContent';
import SpinnerLoader from '../../../../components/SpinnerLoader/SpinerLoader';
import ChoosePlayerEmailModal from '../../../reports/components/ChoosePlayerEmailModal';
import { useUser } from '../../../common/hooks/useUser';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useBearerToken } from '../../../common/hooks/useBearerToken';
import ReportCard from './ReportCard';
import { usePersistentState } from '../../../../persistent-state/persistent-state';
import { CrossIcon } from '../../../../components/Icons';
import ReactPaginate from 'react-paginate';
import './pagination.scss';

const addStylesToSearchInput = (current: InputStyles) => ({
  ...current,
  root: `${current.root} AccountContainer__reports-search-icon`,
});

const addStylesToSortButton = (current: ButtonStyles) => ({
  ...current,
  root: `${current.root} AccountContainer__reports-sort-button`,
});

const { reportsService } = DependencyContainer.getInstance();

const Reports: FunctionComponent = () => {
  const [reports, setReports] = useRecoilState(reportsAtom);
  const user = useUser();
  const reportsLoading = useRecoilValue(reportsLoadingAtom);
  const redirectToRoute = useRedirectToRoute();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [sorting, setSorting] = useState<'asc' | 'desc'>('desc');
  const token = useBearerToken();
  const reportsSelectedUserEmail = useRecoilValue(
    reportsSelectedUserEmailSelector,
  );
  const [selectedUserEmail, setReportsSelectedUserEmail] = usePersistentState(
    'reportsSelectedUserEmail',
  );

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const reportsPerPage = 9;
  let pageCount = 1;
  if (reports) {
    pageCount = Math.ceil(
      reports.length / reportsPerPage || 0 / reportsPerPage,
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const offset = currentPage * reportsPerPage;
  const startIndex = currentPage * reportsPerPage;
  const endIndex = startIndex + reportsPerPage;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const currentReports = reports?.slice(startIndex, endIndex);

  const handlePageClick = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const onReportClick = (report: Report) => {
    redirectToRoute(AppRoutes.Report, {
      id: report.id,
    });
  };

  const onReportToggle = async (report: Report) => {
    if (!reportsSelectedUserEmail || !token) {
      return;
    }
    const successMessage = report.is_approved
      ? 'Report has been set to Unapproved'
      : 'Report has been approved';

    try {
      await reportsService.toggleReport(
        report.id,
        token,
        reportsSelectedUserEmail,
      );
      setReports((reports) =>
        reports?.map((r: Report) => {
          if (r.id === report.id) {
            return {
              ...r,
              is_approved: !r.is_approved,
            };
          }
          return r;
        }),
      );
      setTimeout(() => {
        alert(successMessage);
      }, 300);
    } catch (error) {
      alert('Something went wrong');
    }
  };
  const showTopBar = false;

  return (
    <div className="AccountContainer__reports">
      {user.isAdmin && (
        <div className="AccountContainer__admin-bar">
          <Button
            size="small"
            variant="tertiary"
            type="button"
            onClick={() => setIsModalOpened(true)}
          >
            {selectedUserEmail ? (
              <>
                Reports for <span>{reportsSelectedUserEmail}</span>
              </>
            ) : (
              'Set Player Email'
            )}
          </Button>
          {selectedUserEmail && (
            <Button
              size="small"
              variant="link"
              type="button"
              onClick={() => setReportsSelectedUserEmail(undefined)}
            >
              <CrossIcon />
            </Button>
          )}
        </div>
      )}
      {showTopBar && (
        <div className="AccountContainer__reports-top-bar">
          <div className="AccountContainer__reports-search">
            <Input
              classes={addStylesToSearchInput}
              isSearch
              type="text"
              placeholder="Search report"
            />
          </div>
          <div className="AccountContainer__reports-sort">
            Sort by
            <Button
              onClick={() => setSorting('asc')}
              classes={addStylesToSortButton}
              size="small"
              disabled={sorting === 'asc'}
              variant="link"
            >
              Date ASC
            </Button>
            <Button
              onClick={() => setSorting('desc')}
              classes={addStylesToSortButton}
              size="small"
              disabled={sorting === 'desc'}
              variant="link"
            >
              Date DESC
            </Button>
          </div>
        </div>
      )}
      <PendingContent
        classes={(current: PendingContentStyles) => ({
          ...current,
          root: `${current.root} AccountContainer__reports-loading`,
        })}
        loading={reportsLoading}
        hideContent
        loader={SpinnerLoader}
      >
        <div className="AccountContainer__reports-wrapper">
          {currentReports && currentReports.length === 0 && (
            <p>You don't have any reports yet.</p>
          )}
          {currentReports &&
            currentReports.length > 0 &&
            currentReports.map((report, index) => (
              <ReportCard
                report={report}
                onReportClick={onReportClick}
                onReportToggle={onReportToggle}
                key={index}
              />
            ))}
        </div>
        <div className="pagination-container">
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={({ selected }) => handlePageClick(selected)}
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </PendingContent>
      <ChoosePlayerEmailModal
        isOpen={isModalOpened}
        onRequestClose={() => setIsModalOpened(false)}
      />
    </div>
  );
};

export default Reports;

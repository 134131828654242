import { PlayerRoles } from '../common/types';
import { ClassesModifier } from '../common/hooks/useClasses';
import { CardStyles } from '../../components/Card/Card';

export enum LevelStates {
  Start = 'start',
  Ready = 'ready',
  Playing = 'playing',
  Degraded = 'degraded',
  IncidentRecord = 'incident_record',
  CommentsIn5Minutes = 'comms_in_5_mins',
  IdentifyScope = 'identify_scope',
  IdentifySeverity = 'identify_severity',
  IncidentRecordHint = 'incident_record_hint',
  IdentifyScopeHint = 'identify_scope_hint',
  IdentifySeverityHint = 'identify_severity_hint',
  StarIncidentRecord = 'star_incident_record',
  StarCommentsIn5Minutes = 'star_comms_in_5_mins',
  StarIdentifyScope = 'star_identify_scope',
  StarIdentifySeverity = 'star_identify_severity',
  StarQualifyTheProblem = 'star_qualify_the_problem',
  HintIncidentRecord = 'hint_incident_record',
  HintIdentifyScope = 'hint_identify_scope',
  HintIdentifySeverity = 'hint_identify_severity',

  Recovered = 'recovered',
  WrapUp = 'wrap_up',
  Paused = 'paused',
  Completed = 'completed',
  OutOfTime = 'out_of_time',
  Aborted = 'aborted',
  Cleanup = 'cleanup',
}

export type ReportScore = {
  earned: number;
  of: number;
};

export type Report = {
  id: number;
  level: {
    id: number;
    displayName: number;
  };
  scenario: {
    id: number;
    displayName: string;
  };
  player_email: string;
  session_start: Date;
  session_duration: number;
  is_approved: boolean;
  role: PlayerRoles;
  score: ReportScore;
};

export type ReportTimeLineEvent = {
  id: number;
  state: LevelStates;
  description: string;
  info: string;
  time: Date;
};

export type ReportGenericCardProps<T> = {
  stylesProvider: ClassesModifier<CardStyles>;
} & T;

export type ReportPlayerAssessment = {
  how_challenging: number;
  how_stressful: number;
  how_enjoyable: number;
};

export enum FeedbackTypes {
  Positive = 'Positive',
  Negative = 'Negative',
  Hint = 'Hint',
  LearnedLastSession = 'LearnedLastSession',
  LearnedToday = 'LearnedToday',
  BringNextSession = 'BringNextSession',
  ChallengeReason = 'challenge_reason',
  StressReason = 'stress_reason',
  EnjoyReason = 'enjoy_reason',
  AgreeWithStatement = 'NumOfAgreement',
  DrillProgression = 'ProgressionFeedback',
  LearnedTodayVol2 = 'LearnedTodayV2',
}

export type Feedback = {
  type: FeedbackTypes;
  body: string;
  created_at: Date;
  from_uptimelabs: boolean;
};

export type ReadyToPlay = {
  infraReady: boolean;
};

export type Session = {
  sessionId: number;
  player: string;
  scenario: string;
  level: string;
  gameLength: number;
  sessionDuration: number;
  businessComsChannel: string;
  incidentChannel: string;
  sessionInfra: string;
  lastState: string;
};

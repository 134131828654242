import {
  EnvironmentUrlGetter,
  Scenario,
  ScenarioDifficulties,
  ScenarioLevels,
  ScenarioStatuses,
} from '../types';
import OnlineBoutiqueLevel3 from '../components/introductions/online-boutique/levels/OnlineBoutiqueLevel3';

const level2Description = [
  [
    {
      sectionTitle: 'Communication',
      listedElements: [
        'Support incident manager- timely updates. Precise comms',
      ],
    },
    {
      sectionTitle: 'Triage/Impact Analysis',
      listedElements: [
        'Qualifies the problem',
        'Tries to see the problem first hand',
      ],
    },
    {
      sectionTitle: 'Troubleshooting strategy',
      listedElements: [
        'Show ability to break the problem into smaller set',
        'Workouts dependencies and flow of data',
      ],
    },
    {
      sectionTitle: 'Technical troubleshooting skills',
      listedElements: ['High level K8 concepts '],
    },
  ],
];

const level3Description = [<OnlineBoutiqueLevel3 />];

const commonEnvironmentUrlGetterKeys: EnvironmentUrlGetter[] = [
  {
    label: 'Slack',
    key: 'getUrlForSlack',
    highlighted: true,
  },
  {
    label: 'E-commerce platform',
    key: 'getUrlForOnlineBoutique',
  },
  {
    label: 'Kubernetes Dashboard',
    key: 'getUrlForDashboard',
  },
  {
    label: 'Incident Management dashboard',
    key: 'getUrlForIncidentManagement',
  },
  {
    label: 'Online Boutique Monitoring dashboard',
    key: 'getUrlForIncidentManagementOBMonitoring',
  },
  {
    label: 'CloudShell - Terminal Access',
    key: 'getUrlForCloudShell',
  },
];

const introOneUrlGetterKeys: EnvironmentUrlGetter[] = [
  {
    label: 'Slack',
    key: 'getUrlForSlack',
    highlighted: true,
  },
];

const incidentManagerEnvironmentUrlGetterKeys: EnvironmentUrlGetter[] = [
  {
    label: 'Slack',
    key: 'getUrlForSlack',
    highlighted: true,
  },
  {
    label: 'E-commerce platform',
    key: 'getUrlForOnlineBoutique',
  },
  {
    label: 'Incident Management dashboard',
    key: 'getUrlForIncidentManagement',
  },
  {
    label: 'Online Boutique Monitoring dashboard',
    key: 'getUrlForIncidentManagementOBMonitoring',
  },
];

const onlineBoutiqueMock: Scenario = {
  id: 'online-boutique',
  title: 'Online Boutique',
  difficulty: ScenarioDifficulties.Low,
  status: ScenarioStatuses.Unlocked,
  outageCostPerMinute: 1000,
  allEnvironmentUrlGetterKeys: commonEnvironmentUrlGetterKeys,
  levels: [
    {
      level: ScenarioLevels.Level1,
      name: 'Level 1 - Welcome party',
      timeInSeconds: 15 * 60,
      // timeInSeconds: 10,
      isCompleted: false,
      isAvailable: true,
      description: [
        [
          {
            sectionTitle: 'Learning Objective:',
            listedElements: [
              `Familiarise with OB slack.`,
              `Get to know the team members.`,
            ],
          },
          {
            sectionTitle: 'Your story',
            listedElements: [
              `It's your first day at Online Boutique and the team are excited to meet you 
                Let's get you up to speed with the team and how we work.`,
              `When you press start, a slack session will launch and the team will be there to greet you. Good Luck!`,
            ],
          },
          {
            sectionTitle: 'What should you expect?',
            listedElements: [
              `When you press start, you will be redirected to scenario player. New browser tab with Slack. If it won't appear, don't worry, you can always open them manually by pressing the corresponding buttons.`,
              'Remember, you have limited time to complete your scenario, so be quick! When you run out of the time, all browser tabs will be closed immediately.',
            ],
          },
        ],
      ],
      audio: '/audio/generic_level_intro.mp3',
      environmentUrlGettersKeys: [
        {
          label: 'Slack',
          key: 'getUrlForSlack',
          highlighted: true,
        },
      ],
    },
    {
      level: ScenarioLevels.Level2,
      name: 'Level 2 - Onboarding',
      timeInSeconds: 15 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level2Description,
      audio: '/audio/ob2_uk_male_brian.mp3',
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level3,
      name: 'Level 3 - Too good to be true',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level4,
      name: 'Level 4',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level5,
      name: 'Level 5',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level6,
      name: 'Level 6',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level7,
      name: 'Level 7',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level8,
      name: 'Level 8',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level9,
      name: 'Level 9',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level10,
      name: 'Level 10',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level11,
      name: 'Level 11',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.DevelopmentDrill,
      name: 'Level 12',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level12,
      name: 'Development drill',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level13,
      name: 'Level 13',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level14,
      name: 'Level 14',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level15,
      name: 'Level 15',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level16,
      name: 'Level 16',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level17,
      name: 'Level 17',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level18,
      name: 'Level 18',
      timeInSeconds: 25 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level19,
      name: 'Level 19',
      timeInSeconds: 25 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level20,
      name: 'Level 20',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level21,
      name: 'Level 21',
      timeInSeconds: 10 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: introOneUrlGetterKeys,
      environmentUrlGettersKeysIM: introOneUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level22,
      name: 'Level 22',
      timeInSeconds: 10 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level23,
      name: 'Level 23',
      timeInSeconds: 10 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: introOneUrlGetterKeys,
      environmentUrlGettersKeysIM: introOneUrlGetterKeys,
    },
    {
      level: ScenarioLevels.Level24,
      name: 'Level 24',
      timeInSeconds: 25 * 60,
      isCompleted: false,
      isAvailable: true,
      description: level3Description,
      environmentUrlGettersKeysSRE: commonEnvironmentUrlGetterKeys,
      environmentUrlGettersKeysIM: incidentManagerEnvironmentUrlGetterKeys,
    },
  ],
};
export default onlineBoutiqueMock;

import React, { FormEvent } from 'react';
import cx from 'classnames';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';
import { SearchIcon } from '../Icons';
import { FieldInputProps, FormikProps } from 'formik';

export type InputProps = {
  isSearch?: boolean;
  field?: FieldInputProps<string>;
  form?: FormikProps<string>;
} & JSX.IntrinsicElements['input'];

export type InputStyles = {
  root: string;
  input: string;
};

const Input: AdjustableComponent<InputProps, InputStyles> = ({
  children,
  classes,
  isSearch = false,
  ref,
  form,
  field,
  ...inputProps
}) => {
  const styles = useClasses(
    {
      root: cx('Input', {
        'Input--search': isSearch,
      }),
      input: cx('Input__input'),
    },
    classes,
  );

  const onInput = (e: FormEvent<HTMLInputElement>) => {
    inputProps?.onInput?.(e);
    if (form) {
      form.setFieldValue(field!.name, e.currentTarget.value);
    }
  };

  return (
    <div className={styles.root}>
      {isSearch && <SearchIcon width={16} height={16} />}
      <input
        {...inputProps}
        value={field?.value}
        className={styles.input}
        onInput={onInput}
      />
    </div>
  );
};

export default Input;

import React from 'react';
import cx from 'classnames';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

export type AlertProps = {
  type?: 'warning';
} & JSX.IntrinsicElements['div'];

export type AlertStyles = {
  root: string;
};

const Alert: AdjustableComponent<AlertProps, AlertStyles> = ({
  children,
  classes,
  type = 'warning',
  ...rest
}) => {
  const styles = useClasses(
    {
      root: cx('Alert', {
        [`Alert--${type}`]: true,
      }),
    },
    classes,
  );
  return (
    <div className={styles.root} {...rest}>
      {children}
    </div>
  );
};

export default Alert;

import { FunctionComponent } from 'react';
import Modal, { ModalStyles } from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody';
import ModalHeader from '../../../../components/Modal/ModalHeader';
import { DeveloperIcon, ManagerIcon } from '../../../../components/Icons';
import Card, { CardStyles } from '../../../../components/Card/Card';
import { usePersistentState } from '../../../../persistent-state/persistent-state';
import { PlayerRoles } from '../../types';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useUser } from '../../hooks/useUser';

export type RoleSelectorModal = {
  isOpen: boolean;
  onRequestClose: () => void;
};

const addStylesToCard = (current: CardStyles) => ({
  ...current,
  root: `${current.root} RoleSelectorModal__card`,
});

const { playerService } = DependencyContainer.getInstance();

const RoleSelectorModal: FunctionComponent<RoleSelectorModal> = ({
  isOpen,
  onRequestClose,
}) => {
  const [, setPlayerRole] = usePersistentState('selectedPlayerRole');
  const user = useUser();

  const onSelectRole = async (role: PlayerRoles) => {
    setPlayerRole(role);
    if (user.email) {
      await playerService.savePlayerRole(user.email, role);
    }
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} RoleSelectorModal`,
      })}
    >
      <ModalHeader disableClose />
      <ModalBody>
        <h2 className="RoleSelectorModal__title">Select your role</h2>
        <p className="RoleSelectorModal__subtitle">
          Before you start, please select your player role. <br />
          You can always change it by clicking on your avatar on the topbar.
        </p>
        <div className="RoleSelectorModal__cards">
          <Card
            classes={addStylesToCard}
            collapsible={false}
            onClick={() => onSelectRole(PlayerRoles.SiteReliabilityEngineer)}
          >
            <DeveloperIcon className="RoleSelectorModal__icon" />
            Site Reliability Engineer
          </Card>
          <Card
            classes={addStylesToCard}
            collapsible={false}
            onClick={() => onSelectRole(PlayerRoles.IncidentManager)}
          >
            <ManagerIcon className="RoleSelectorModal__icon" />
            Incident Manager
          </Card>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RoleSelectorModal;

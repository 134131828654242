import React from 'react';
import cx from 'classnames';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

export type BadgeProps = {
  type?: 'warning' | 'success' | 'danger' | 'info' | 'link';
} & JSX.IntrinsicElements['span'];

export type BadgeStyles = {
  root: string;
};

const Badge: AdjustableComponent<BadgeProps, BadgeStyles> = ({
  children,
  classes,
  type = 'warning',
  ...rest
}) => {
  const styles = useClasses(
    {
      root: cx('Badge', {
        [`Badge--${type}`]: true,
      }),
    },
    classes,
  );
  return (
    <span className={styles.root} {...rest}>
      {children}
    </span>
  );
};

export default Badge;

import React, { FunctionComponent } from 'react';
import { IntroductionPlayerStep } from '../../IntroductionPlayer/IntroductionPlayer';
import { Urls } from '../../../../../config/urls';
import Joyride, { Step } from 'react-joyride';
import { usePersistentState } from '../../../../../persistent-state/persistent-state';
import AudioPlayer, {
  AudioPlayerStyles,
} from '../../../../../components/AudioPlayer/AudioPlayer';
import {
  contentConfiguration,
  joyrideStyles,
} from '../../../../../config/config';
import { Features } from '../../../../../config/features';
import Button from '../../../../../components/Button/Button';
import { useFeatureFlag } from '../../../../common/hooks/useFeatureFlag';
import { DependencyContainer } from '../../../../../DependencyContainer';
import { useFetchData } from '../../../../common/hooks/useFetchData';
import { Page, WordpressContent } from '../../../../content/types';
import PendingContent from '../../../../../components/PendingContent/PendingContent';
import SpinnerLoader from '../../../../../components/SpinnerLoader/SpinerLoader';

const { contentService } = DependencyContainer.getInstance();

const OnlineBoutiqueIntroductionStep2: FunctionComponent<
  IntroductionPlayerStep
> = () => {
  const [tourRan] = usePersistentState('tourRan');
  const isOnBoardingAvailable = useFeatureFlag(Features.OnBoarding);
  const [loading, wordpressPage, error] = useFetchData<
    WordpressContent<Page>,
    Error
  >(() =>
    contentService.getPageBySlug(
      contentConfiguration.OnlineBoutique.introductionStep2ContentSlug,
    ),
  );

  const goToOnlineBoutique = () => {
    if (!window.envs.REACT_APP_ENV) {
      return;
    }
    const url = Urls['getUrlForObDemo'](window.envs.REACT_APP_ENV);
    window.open(url, '_blank');
  };

  const steps: Step[] = [
    {
      target: '#OnlineBoutiqueIntroduction__hipster-image',
      content:
        'You can click on this image to go to the Online Boutique e-commerce platform.',
    },
  ];

  return (
    <div className="OnlineBoutiqueIntroduction">
      <AudioPlayer
        source="/audio/website_intro.mp3"
        classes={(current: AudioPlayerStyles) => ({
          ...current,
          root: `${current.root} OnlineBoutiqueIntroduction__audio-player`,
        })}
      />
      {isOnBoardingAvailable() && (
        <Joyride
          steps={steps}
          stepIndex={0}
          //continuous
          run={!tourRan}
          scrollToFirstStep
          showSkipButton
          styles={joyrideStyles}
        />
      )}
      <PendingContent
        loading={loading}
        isError={Boolean(error)}
        hideContent
        loader={SpinnerLoader}
      >
        <div className="OnlineBoutiqueIntroduction__row">
          <div className="OnlineBoutiqueIntroduction__image-container">
            {wordpressPage && (
              <a
                href="#"
                id="OnlineBoutiqueIntroduction__hipster-image"
                onClick={() => goToOnlineBoutique()}
                dangerouslySetInnerHTML={{
                  __html: wordpressPage.content.rendered,
                }}
              />
            )}
            <div className="OnlineBoutiqueIntroduction__cta">
              <Button
                size="small"
                variant="secondary"
                onClick={() => goToOnlineBoutique()}
              >
                Visit website
              </Button>
            </div>
          </div>
        </div>
      </PendingContent>
    </div>
  );
};

export default OnlineBoutiqueIntroductionStep2;

import React from 'react';
import cx from 'classnames';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

export type ButtonVariant =
  | 'superprimary'
  | 'primary'
  | 'secondary'
  | 'link'
  | 'tertiary';
export type ButtonSize = 'small' | 'large';
export type ButtonProps = {
  variant?: ButtonVariant;
  pulsed?: boolean;
  size?: ButtonSize;
} & JSX.IntrinsicElements['button'];
export type ButtonStyles = {
  root: string;
};

const mapVariantToPulse: Record<string, string> = {
  superprimary: 'Button--pulse-green',
};

const Button: AdjustableComponent<ButtonProps, ButtonStyles> = ({
  variant = 'primary',
  size = 'large',
  pulsed = false,
  classes,
  children,
  ...restProps
}) => {
  const pulseClassName = mapVariantToPulse[variant] || '';
  const styles = useClasses(
    {
      root: cx('Button', {
        [`Button--${variant}`]: variant,
        [`Button--${size}`]: size,
        [pulseClassName]: pulsed,
      }),
    },
    classes,
  );
  return (
    <button className={styles.root} {...restProps}>
      {children}
    </button>
  );
};

export default Button;

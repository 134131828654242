import { useKeycloak } from '@react-keycloak/web';
import React, { FunctionComponent } from 'react';

const ForAuthenticated: FunctionComponent = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  if (!initialized) {
    return null;
  }

  if (initialized && !isLoggedIn) {
    keycloak.login();
  }

  return <>{isLoggedIn ? children : null}</>;
};

export default ForAuthenticated;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Post } from '../content/types';
import { DependencyContainer } from '../../DependencyContainer';
import { useFetchData } from '../common/hooks/useFetchData';
import { Link } from 'react-router-dom';
import './LearningCenter.scss';

interface BlogPostListProps {
  posts: Post[];
}

const parsePosts = (postsData: any[]): Post[] => {
  return postsData.map((post) => ({
    id: post.id,
    guid: post.guid.rendered,
    title: post.title.rendered,
    content: post.content.rendered,
    excerpt: post.excerpt.rendered,
    featured_media: post.featured_media,
    imageUrl: '',
  }));
};

const PostList: React.FC<BlogPostListProps> = ({ posts: initialPosts }) => {
  const { postsService } = DependencyContainer.getInstance();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, posts, error, fetchPosts, complete] = useFetchData<
    Post[],
    any
  >(
    async () => {
      const postsData = await postsService.getPosts();
      return parsePosts(postsData!);
    },
    undefined,
    [postsService],
  );

  useEffect(() => {
    if (complete && error) {
      console.error('Error fetching posts:', error);
    }
  }, [complete, error]);

  const backgroundImage = (post: Post) => {
    if (post.featured_media) {
      return `url(${post.featured_media})`;
    }
    return '';
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !posts) {
    return <div>Error fetching posts. Please try again later.</div>;
  }

  if (posts.length === 0) {
    return <div>No posts found.</div>;
  }

  return (
    <div className="posts-list-container">
      {posts.map((post: Post) => (
        <div className="posts-list-container-post-card">
          <Link to={`/learning/${post.id}`} key={post.id}>
            <div
              className="posts-list-container-post-card-image"
              style={{ backgroundImage: backgroundImage(post) }}
            ></div>{' '}
          </Link>
          <div className="posts-list-container-post-card-details">
            <Link to={`/learning/${post.id}`}>
              <h2 className="posts-list-container-post-card-title">
                {post.title}
              </h2>
              <p
                className="posts-list-container-post-card-excerpt"
                dangerouslySetInnerHTML={{ __html: post.excerpt }}
              ></p>
            </Link>
            <div className="posts-list-container-post-card-read-more">
              <Link to={`/learning/${post.id}`}>Read More</Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostList;

import { useNavigate } from 'react-router';
import { AppRoutes } from '../../../config/routes';

export type UseRedirectToRouteReturnType = (
  routeName: AppRoutes,
  params?: any,
) => void;

export const useRedirectToRoute = (): UseRedirectToRouteReturnType => {
  const navigate = useNavigate();

  return (routeName: AppRoutes, params?: any) => {
    const allParams = Object.keys(params || {});
    const route =
      params !== undefined
        ? allParams.reduce(
            (acc: string, param: string) =>
              acc.replace(`:${param}`, params[param]),
            routeName,
          )
        : routeName;
    navigate(route);
  };
};

import React, { FunctionComponent } from 'react';

const OnlineBoutiqueLevel3: FunctionComponent = () => {
  return (
    <div className="OnlineBoutiqueIntroduction">
      <p>
        The generic Incident Response lifecycle can be expressed in the helpful
        mnemonic STAR.
      </p>
      <ul>
        <li>
          S - <strong>S</strong>ize up the issue
        </li>
        <li>
          T - <strong>T</strong>riage
        </li>
        <li>
          A - Develop the <strong>A</strong>ction plan and resolve the issue
        </li>
        <li>
          R - <strong>R</strong>eview the incidence and consolidate learnings
        </li>
      </ul>
      <p>
        This framework can be used as a basis for developing your own
        organisation’s incident response protocol. It’s helpful for all incident
        responders to be aware of the incident response protocol and to be able
        to identify and perform the necessary actions in each stage.
      </p>
      <p>
        The following level focuses on "S" - Size up the issue. When you
        successfully complete this level, you’ll have experienced how to size up
        an incident and will be able to apply this learning in your own place of
        work.
      </p>
    </div>
  );
};

export default OnlineBoutiqueLevel3;

import React from 'react';

const getAppVersion = (): string => {
  try {
    // @ts-ignore
    return __VERSION__;
  } catch (error) {
    return 'unknown';
  }
};

const VersionContainer = () => {
  return (
    <div className="VersionContainer">
      App version:
      <span className="VersionContainer__indicator">{getAppVersion()}</span>
    </div>
  );
};

export default VersionContainer;

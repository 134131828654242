import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'classnames';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';
import { XOR } from '../../features/common/types';
import { omit } from '../../features/common/helpers/objects.helper';
import TextLoader from '../TextLoader/TextLoader';

export type PendingContentStyles = {
  root: string;
  loader: string;
  error: string;
  ReadyToPlay: string;
};

export type PendingContentProps = {
  loading: boolean;
} & Partial<
  {
    loader: FunctionComponent;
    message: ReactNode | string;
    isError: boolean;
    ReadyToPlay: boolean;
  } & XOR<{ hideContent: boolean }, { transparent: boolean }>
>;

export type LoadableErrorProps = JSX.IntrinsicElements['div'] & {
  message?: ReactNode | string;
};

const PendingContent: AdjustableComponent<
  PendingContentProps,
  PendingContentStyles
> = (props) => {
  const propsToPass = omit(
    props,
    'children',
    'classes',
    'loading',
    'isError',
    'hideContent',
    'loader',
    'transparent',
    'ReadyToPlay',
  );

  const styles = useClasses(
    {
      root: cx('PendingContent', {
        'PendingContent--loading': props.loading,
        'PendingContent--hide-content': props.hideContent,
        'PendingContent--curtain': !props.transparent,
        PendingContent__ReadyToPlay: props.ReadyToPlay,
      }),
      loader: 'PendingContent__loader',
      error: 'PendingContent__error',
      ReadyToPlay: 'PendingContent__ReadyToPlay',
    },
    props.classes,
  );

  const message = props.message || 'Something went wrong';
  const loaderProps = {
    visible: props.loading,
    classes: (current: any) => ({
      ...current,
      root: `${current.root} ${styles.loader}`,
    }),
  };
  const Loader = props.loader || TextLoader;

  return (
    <div {...propsToPass} className={styles.root}>
      <Loader {...loaderProps} />
      {props.isError ? (
        <PendingContentError className={styles.error} message={message} />
      ) : (
        props.children
      )}
    </div>
  );
};

export const PendingContentError: FunctionComponent<LoadableErrorProps> = ({
  message,
  ...props
}) => {
  return <div {...props}>{message}</div>;
};

export default PendingContent;

import React, { useEffect } from 'react';
import Button, { ButtonStyles } from '../../../components/Button/Button';
import { useKeycloak } from '@react-keycloak/web';
import useGetTenant from '../../common/hooks/useGetTenant';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import { AppRoutes } from '../../../config/routes';
import { isVideoModalOpenedAtom } from '../../../state';
import { useSetRecoilState } from 'recoil';
import { CheckIcon } from '../../../components/Icons';

const PendingAccountContainer = () => {
  const { keycloak } = useKeycloak();
  const tenant = useGetTenant();
  const goToRoute = useRedirectToRoute();
  const setIsVideoModalOpened = useSetRecoilState(isVideoModalOpenedAtom);

  const onLogout = async () => {
    await keycloak.logout();
  };

  useEffect(() => {
    if (tenant !== undefined) {
      goToRoute(AppRoutes.Account);
    }
  }, []);

  return (
    <div className="PendingAccountContainer">
      <h1>Thanks for signing up!</h1>
      <p>
        We are activating your account, meanwhile please review preparation
        steps.
      </p>
      <div className="PendingAccountContainer__boxes">
        <div className="PendingAccountContainer__box">
          <p> First, watch the video to understand how our platform works.</p>
          <Button size="small" onClick={() => setIsVideoModalOpened(true)}>
            Play Video
          </Button>
        </div>
        <div className="PendingAccountContainer__box">
          <p>
            You can read up on the{' '}
            <a
              target="_blank"
              href="https://uptimelabs.io/top-10-incident-management-problems/"
            >
              Top 10 Incident Management Problems
            </a>{' '}
            to get a better understanding of the problems you'll be running into
            in the upcoming drills.
          </p>
        </div>
        <div className="PendingAccountContainer__box">
          <p>
            Please make sure that slack is able to run on your browser. <br />
          </p>
          <a
            className="Button Button--pulse-orange Button--small "
            id="button--link"
            target="_blank"
            href="https://uptimelabs-metaverse.slack.com/"
          >
            Test Slack
          </a>
        </div>
      </div>

      <h3>Please be aware that:</h3>
      <div className="PendingAccountContainer__checks">
        <div className="PendingAccountContainer__check">
          <CheckIcon /> We will ask you to share your screen for user experience
          research purposes.
        </div>

        <div className="PendingAccountContainer__check">
          <CheckIcon /> We will also record the session for our product
          improvement purposes.
        </div>

        <div className="PendingAccountContainer__check">
          <CheckIcon /> Please let us know if you are not comfortable with
          recording the session or sharing your desktop.
        </div>
      </div>

      <Button
        classes={(current: ButtonStyles) => ({
          ...current,
          root: `${current.root} PendingAccountContainer__logout`,
        })}
        onClick={() => onLogout()}
        variant="link"
        size="small"
      >
        Logout
      </Button>
    </div>
  );
};

export default PendingAccountContainer;

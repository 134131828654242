import React from 'react';
import cx from 'classnames';
import {
  colorFromText,
  makeInitials,
  stripEmojis,
} from '../../features/common/helpers/text.helper';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

export type AvatarVariants = 'small' | 'large';
export type AvatarProps = {
  fullName: string;
  userlevel?: number;
  imageSrc?: string;
  variant?: AvatarVariants;
} & JSX.IntrinsicElements['div'];

export type AvatarStyles = {
  root: string;
  badge: string;
};

const Avatar: AdjustableComponent<AvatarProps, AvatarStyles> = ({
  fullName,
  variant = 'small',
  imageSrc,
  classes,
  ...rest
}) => {
  const styles = useClasses(
    {
      root: cx('Avatar', {
        [`Avatar--${variant}`]: variant,
      }),
      badge: 'Avatar__badge',
    },
    classes,
  );
  const initials = makeInitials(stripEmojis(fullName));
  const bg = colorFromText(fullName);
  const inlineStyles = {
    backgroundColor: bg,
  };

  return (
    <div
      className={styles.root}
      title={fullName}
      {...rest}
      style={inlineStyles}
    >
      {!imageSrc && initials}
      {imageSrc && <img src={imageSrc} alt={initials} />}
    </div>
  );
};

export default Avatar;

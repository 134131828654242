import { DependencyContainer } from '../../../DependencyContainer';
import { Post, WordpressContent } from '../../content/types';

export default class PostsService {
  constructor(private readonly factory: DependencyContainer) {}

  private hydratePostWithContent(post: Post): Post {
    return {
      id: post.id,
      title: post.title,
      guid: post.guid,
      content: post.content,
      excerpt: post.excerpt,
      featured_media: post.featured_media,
      imageUrl: '',
    };
  }

  async getPosts(): Promise<Post[]> {
    try {
      const response = await this.factory.postsClient.fetchPosts();
      const data = response.data;
      const posts = await Promise.all(
        data.map(async (post) => {
          if (post.featured_media) {
            const postImage = await fetch(
              `https://content.dev.uptimelabs.io/wp-json/wp/v2/media/${post.featured_media}`,
            );
            post.featured_media = (await postImage.json()).guid.rendered;
          }
          return this.hydratePostWithContent(post);
        }),
      );
      return posts;
    } catch (error) {
      console.error('Error fetching posts:', error);
      return [];
    }
  }

  async getPost(id: string): Promise<WordpressContent<Post>> {
    try {
      const response = await this.factory.postsClient.fetchPost(id);
      const post = response.data; // Access the 'data' property of the response object
      if (post.featured_media) {
        const postImage = await fetch(
          `https://content.dev.uptimelabs.io/wp-json/wp/v2/media/${post.featured_media}`,
        );
        post.featured_media = (await postImage.json()).guid.rendered;
      }
      return post;
    } catch (error) {
      console.error('Error fetching post:', error);
      return {} as WordpressContent<Post>;
    }
  }
}

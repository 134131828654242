import { getEnvironment } from './environment';

type SlackMappingObject = Record<string, string>;

const mapUatTenantToSlackChannel: SlackMappingObject = {
  tenant1: 'tenant1',
  tenant2: 'tenant2',
};

const mapProdTenantToSlackChannel: SlackMappingObject = {
  nakedwines: 'nakedwines',
  trainline: 'trainline',
  uptimelabs: 'uptimelabs-internal',
  ig: 'ig',
};

const mapEnvToMappingObject: Record<string, SlackMappingObject> = {
  uat: mapUatTenantToSlackChannel,
  prod: mapProdTenantToSlackChannel,
};

export const getSlackChannelForTenant = (
  tenant: string,
): string | undefined => {
  let mappingObject = mapUatTenantToSlackChannel;
  const environment = getEnvironment();
  if (environment !== undefined) {
    mappingObject =
      mapEnvToMappingObject[environment] || mapUatTenantToSlackChannel;
  }
  return mappingObject[tenant];
};

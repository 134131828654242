/* eslint-disable @typescript-eslint/no-unused-vars */
import { DependencyContainer } from '../../../../../DependencyContainer';

import { HttpClient } from '../../../../../HttpClient';
import { AxiosResponse } from 'axios';
import { ApiUrls } from '../../../../../config/urls';
import { getApiUrl } from '../../../../../config/environment';
import { CreateLobbyPayload, ExistingLobbyPayload, Lobby} from '../types';
import { config } from 'process';

const configOverride = {
  baseURL: getApiUrl(),
};

export default class LobbyClient extends HttpClient {
  constructor(protected readonly factory: DependencyContainer) {
    super(factory);
  }

  async createLobby(
    token: string,
    payload: CreateLobbyPayload,
  ): Promise<AxiosResponse<Lobby>> {
    this.setToken(token);
    const response = await this.post<Lobby>(
      ApiUrls.getUrlForLobbyCreation(),
      payload,
      configOverride,
    );
    return response;
  }

  async getLobbyByEmail(
    token: string,
    player: string,
  ): Promise<AxiosResponse<Lobby>> {
    this.setToken(token);

    const response = await this.get<Lobby>(
      ApiUrls.getUrlToFindLobbyByEmail(player),
      configOverride,
    );

    return response;
  }

  async joinLobby(
    token: string,
    payload: ExistingLobbyPayload,
  ): Promise<AxiosResponse<Lobby>> {
    this.setToken(token);
    const response = await this.post<Lobby>(
      ApiUrls.getUrlForJoinLobby(),
      payload,
      configOverride,
    );
    return response;
  }

  async closeLobby(
    token: string,
    payload: ExistingLobbyPayload,
  ): Promise<AxiosResponse<Lobby>> {
    this.setToken(token);
    const response = await this.post<Lobby>(
      ApiUrls.getUrlForCloseLobby(),
      payload,
      configOverride,
    );
    return response;
  }

  async leaveLobby(
    token: string,
    payload: ExistingLobbyPayload,
  ): Promise<AxiosResponse<Lobby>> {
    this.setToken(token);
    const response = await this.post<Lobby>(
      ApiUrls.getUrlForLeaveLobby(),
      payload,
      configOverride,
    );
    return response;
  }
}

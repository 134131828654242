import { PlayerRoles } from './types';

export type SlackMessage = {
  username: string;
  channel: string;
  message: string;
  emojiIcon: string;
};

export enum MqttStaticTopics {
  START_SEQUENCE = 'uptimelabs/events/start_sequence',
  SLACK_MESSAGE = 'uptimelabs/slack/message',
  START_TIMER = 'uptimelabs/events/game/start_timer',
  INVITE_USER_TO_SLACK_CHANNEL = 'uptimelabs/slack/invite',
  UPDATE_GAME_STATUS = 'uptimelabs/events/game/update',
  CONFIGURE_PLAYER = 'uptimelabs/configure/player',
}


export type PlayerSessionUpdatePayload = {
  scenario: string;
  level: number;
  status: string;
  game_remaining_seconds: number;
};

export type BasicGameStatusPayload = {
  player_uid: string;
  player: string;
  scenario: string;
  level: string;
  tenant: string;
};

export type MqttPublishParams =
  | {
    topic: MqttStaticTopics.START_SEQUENCE;
    payload: {
      player_uid: string;
      player: string;
      scenario: string;
      level: number;
      sequence: number;
    };
  }
  | {
    topic: MqttStaticTopics.START_TIMER;
    payload: {
      player: string;
      duration: string; //in seconds
    };
  }
  | {
    topic: MqttStaticTopics.INVITE_USER_TO_SLACK_CHANNEL;
    payload: {
      username: string;
      channel: string;
    };
  }
  | {
    // At this moment we support new_player and JOIN_SESSION types
    topic: MqttStaticTopics.UPDATE_GAME_STATUS;
    payload: BasicGameStatusPayload & {
      type: 'new_player' | 'JOIN_SESSION';
      game_length?: string;
      session_id?: number;
    };
  }
  | {
    topic: MqttStaticTopics.CONFIGURE_PLAYER;
    payload: {
      player: string;
      persona?: PlayerRoles;
      tenant?: string;
    };
  };


export const MqttTopics = {
  getSlackMessageTopic: () => 'uptimelabs/slack/message',
  getPlayerStatus: (role: string, email: string) =>
    `tenants/${role}/players/${email}/status`,
  getStartSequenceTopic: () => MqttStaticTopics.START_SEQUENCE,
  getStartTimerTopic: () => MqttStaticTopics.START_TIMER as const,
  getInviteUserToSlackChannel: () =>
    MqttStaticTopics.INVITE_USER_TO_SLACK_CHANNEL as const,
  getGameRemainingSecondsTopic: (role: string, email: string) =>
    `tenants/${role}/players/${email}/status/game_remaining_seconds`,
  getGameStatusTopic: (player_uid: string) =>
    `uptimelabs/player/${player_uid}/status/current`,
  getSetStatusTopic: () => `uptimelabs/events/game/update`,
  getConfigurePlayerTopic: () => `uptimelabs/configure/player`,
  getPlayersInLobbyTopic: (lobbyId: string) => `uptimelabs/lobby/${lobbyId}/players`,
};

import React from 'react';
import { Link } from 'react-router-dom';

const SiteReliabilityEngineer: React.FC = () => {
  return (
    <div className="__role-intro">
      <div>
        <h3> Welcome to the Online Boutique family!</h3>
      </div>
      <div>
        <p>
          {' '}
          We're thrilled to have you onboard as our new Site Reliability
          Engineer.
        </p>
        <p>
          {' '}
          Familiarise yourself with our ways of working, guided by the{' '}
          <Link to={`/learning/312`}> STAR Method </Link> it will be key to your
          success as a Site Reliability Engineer at OB.
        </p>
      </div>
    </div>
  );
};

export default SiteReliabilityEngineer;

import React, { FunctionComponent } from 'react';
import Button from '../../../components/Button/Button';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import { AppRoutes } from '../../../config/routes';

const LockedScenarioMessage: FunctionComponent = () => {
  const goToRoute = useRedirectToRoute();
  const goToScenarioSelection = () => {
    goToRoute(AppRoutes.Scenarios);
  };

  return (
    <div className="ScenariosContainer__locked-scenario">
      <p>
        This scenario is locked yet. In order to view the content, please
        complete previous one first.
      </p>
      <Button size="small" onClick={() => goToScenarioSelection()}>
        go back to scenario selection
      </Button>
    </div>
  );
};

export default LockedScenarioMessage;

import React, { FunctionComponent } from 'react';

const TableHead: FunctionComponent = ({ children }) => {
  return (
    <thead className="Table__head">
      <tr>{children}</tr>
    </thead>
  );
};

export default TableHead;

import bez from '../../../../../assets/images/bez.png';
import bob from '../../../../../assets/images/bob.png';
import daniel from '../../../../../assets/images/daniel.png';
import hamed from '../../../../../assets/images/hamed.png';
import tanya from '../../../../../assets/images/tanya.png';
import shay from '../../../../../assets/images/shay.png';
import tinus from '../../../../../assets/images/tinus.png';
import phil from '../../../../../assets/images/phil.png';
import maya from '../../../../../assets/images/maya.png';

export type StaffPerson = {
  name: string;
  image: string;
  position: string;
  description: string;
  deutschebankposition?: string;
};
const staff: Record<string, StaffPerson> = {
  BezJeffos: {
    name: 'Bez Jeffos',
    image: bez,
    position: 'CEO',
    description: `
      Inspirational serial unicorn entrepreneur.
      Type A personality. Still thinks of himself as a developer.
    `,
  },
  TinusLorvald: {
    name: 'Tinus Lorvald',
    image: tinus,
    position: 'CTO',
    description: `
    Our esteemed and inspirational serial unicorn entrepreneur, has a remarkable track record of building multiple unicorn startups. Despite his entrepreneurial success, Bez holds onto his developer identity, bringing a unique blend of visionary leadership and technical insight at OB.
    `,
  },
  TanyaClarke: {
    name: 'Tanya Clarke',
    image: tanya,
    position: 'Platform Engineer',
    deutschebankposition: 'Cloud Infrastructure Engineer',
    description: `
    With her main area of responsibility being Infrastructure, Tanya is our Lead Platform Architect and an unrivalled all-round expert at Online Boutique. She is the go-to person for tackling intricate challenges and offering insightful solutions, playing a pivotal role in shaping and elevating Online Boutique.
    `,
  },

  PhilPollins: {
    name: 'Phil Pollins',
    image: phil,
    position: 'Incident Manager',
    description: `
    Newest member of the Online Boutique family. Your go-to person for swiftly and efficiently managing, escalating, and resolving incidents. Ready to navigate any issue and keep things running smoothly for our users and stakeholders!
    `,
  },
  Bob: {
    name: 'Bob Johnson ',
    image: bob,
    position: 'Customer services lead',
    deutschebankposition: 'Customer Services Manager',
    description: `
      Leads OB’s 50 strong team of customer services agents and the UK’s biggest chatbot swarm
    `,
  },
  HamedSilatani: {
    name: 'Hamed Silatani',
    image: hamed,
    position: 'Platform Lead',
    deutschebankposition: 'Infrastructure Lead',
    description: `
    A key collaborator alongside Daniel in optimising Online Boutique's Kubernetes (k8s) platform. Renowned for an exceptional track record in providing outstanding support, Hamed plays a crucial role in ensuring the seamless functionality and reliability of our platform's systems.
    `,
  },
  DanielBarrell: {
    name: 'Daniel Barrell',
    image: daniel,
    position: 'Senior Software Engineer',
    deutschebankposition: 'App Support 1',
    description: `
    Daniel's main areas of responsibility are Application and Development. He is a seasoned professional with an extensive skill set spanning Site Reliability Engineering, DevOps practices, Kubernetes orchestration, and Continuous Integration/Continuous Deployment (CI/CD). As the driving force behind our tech operations, Daniel plays a crucial role in sculpting our technological landscape.
    `,
  },
  ShayNewbie: {
    name: 'Shay Newbie',
    image: shay,
    position: 'Junior Software Engineer',
    deutschebankposition: 'App Support 1',
    description: `
     Recently became a part of the Online Boutique team, a fast learner with a keen willingness to assist and always open to asking questions.
    `,
  },
  MayaLowe: {
    name: 'Maya Brown',
    image: maya,
    position: 'Security Analyst',
    description: `
    A seasoned professional in the realm of information security, Maya is instrumental in identifying, evaluating, and mitigating potential security threats, ensuring the integrity and resilience of Online Boutique.
    `,
  },
};

export default staff;

import { useKeycloak } from '@react-keycloak/web';
import React, { FunctionComponent, useEffect } from 'react';
import { AppRoutes } from '../../../config/routes';
import useGetTenant from '../hooks/useGetTenant';
import { useCurrentRoute } from '../hooks/useCurrentRoute';
import { useRedirectToRoute } from '../hooks/useRedirectToRoute';

const ForActivatedAccount: FunctionComponent = ({ children }) => {
  const { initialized } = useKeycloak();
  const tenant = useGetTenant();
  const currentRoute = useCurrentRoute();
  const goToRoute = useRedirectToRoute();

  useEffect(() => {
    if (tenant === undefined && currentRoute() !== AppRoutes.InactiveAccount) {
      goToRoute(AppRoutes.InactiveAccount);
    }
  }, []);

  if (!initialized) {
    return null;
  }

  return <>{children}</>;
};

export default ForActivatedAccount;

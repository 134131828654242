import { useEffect, useState } from 'react';
import { audioVolume } from '../../../config/config';

export type UseAudioValues = [
  boolean,
  CallableFunction,
  CallableFunction,
  CallableFunction,
  boolean,
];

const useAudio = (url: string): UseAudioValues => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState<number>(audioVolume);

  const toggle = () => setPlaying(!playing);

  const toggleVolume = () => {
    setVolume((currentVolume) => (currentVolume ? 0 : audioVolume));
  };

  const flush = () => {
    if (playing) {
      audio.pause();
    }
  };

  useEffect(() => {
    audio.volume = volume;
  }, [volume]);

  useEffect(() => {
    playing ? audio.play().catch(() => setPlaying(false)) : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
      audio.pause();
    };
  }, []);

  return [playing, toggle, flush, toggleVolume, volume === 0];
};

export default useAudio;

import React from 'react';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

interface CarouselButtonProps {
  direction: 'left' | 'right';
  onClick: () => void;
}

const CarouselButton: React.FC<CarouselButtonProps> = ({ direction, onClick }) => {
  return (
    <Button
      onClick={onClick}
      className={`carousel-button ${direction}`}
    >
      {direction === 'left' 
        ? <FontAwesomeIcon icon={faArrowLeft} size="2x" /> 
        : <FontAwesomeIcon icon={faArrowRight} size="2x" />}
    </Button>
  );
};

export default CarouselButton;

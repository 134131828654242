import { DependencyContainer } from '../../../../../DependencyContainer';
import { CreateLobbyPayload, ExistingLobbyPayload, Lobby, LobbyPlayer } from '../types';
import { Urls } from '../../../../../config/urls';
import { getEnvironment } from '../../../../../config/environment';
import { MqttTopics} from '../../../../common/mqtt-topics';

export default class LobbyService {
  constructor(protected readonly factory: DependencyContainer) { }

  private hydrateLobbyWithInfo(lobby: Lobby): Lobby {
    return {
      ...lobby,
    };
  }

  async createLobby(
    token: string,
    payload: CreateLobbyPayload,
  ): Promise<Lobby> {
    try {
      const response = await this.factory.lobbyClient.createLobby(
        token,
        payload,
      );
      const data = response.data;
      const lobby = this.hydrateLobbyWithInfo(data);
      return lobby;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }

  async generateLobbyJoinLink(token: string, lobby: Lobby): Promise<string> {
    try {
      const env = getEnvironment();
      const baseUrl = Urls.getBaseUrl(env);
      const lobbylink = `${baseUrl}/join/${lobby.owner}/scenario/${lobby.scenario}/level/${lobby.level}/lobby/${lobby.id}`;
      return lobbylink;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }

  async joinLobby(
    token: string,
    payload: ExistingLobbyPayload,
  ): Promise<Lobby> {
    try {
      const response = await this.factory.lobbyClient.joinLobby(token, payload);
      const data = response.data;
      const lobby = this.hydrateLobbyWithInfo(data);
      return lobby;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }

  async getLobbyByEmail(token: string, player: string): Promise<Lobby> {
    try {
      const response = await this.factory.lobbyClient.getLobbyByEmail(
        token,
        player,
      );
      const data = response.data;
      const lobby = this.hydrateLobbyWithInfo(data);
      return lobby;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }

  async closeLobby(
    token: string,
    payload: ExistingLobbyPayload,
  ): Promise<Lobby> {
    try {
      const response = await this.factory.lobbyClient.closeLobby(
        token,
        payload,
      );
      const data = response.data;
      const lobby = this.hydrateLobbyWithInfo(data);
      return lobby;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }

  async leaveLobby(
    token: string,
    payload: ExistingLobbyPayload,
  ): Promise<Lobby> {
    try {
      const response = await this.factory.lobbyClient.leaveLobby(
        token,
        payload,
      );
      const data = response.data;
      const lobby = this.hydrateLobbyWithInfo(data);
      return lobby;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }

  setPlayersinLobby(
    lobbyId: string,
    payload: LobbyPlayer[],
  ) : () => void {
    const topic = MqttTopics.getPlayersInLobbyTopic(lobbyId);
    this.factory.mqttService.publish({
      topic: topic,
      payload: payload,
    });
    return () => {
      this.factory.mqttService.unsubscribe(topic);
    };
  }

  getPlayersInLobby(
    lobby_id: string,
    onMessage: (message: LobbyPlayer[]) => void,
  ): () => void {
    const topic = MqttTopics.getPlayersInLobbyTopic(lobby_id);
    this.factory.mqttService.subscribe(topic, onMessage);
    return () => {
      this.factory.mqttService.unsubscribe(topic);
    };
  }
}

import React, { ReactNode, useContext } from 'react';

import { ModalPropsContext } from './Modal';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';
import { CrossIcon } from '../Icons';

export type ModalHeaderProps = {
  onRequestClose: () => {};
  title: ReactNode;
  subtitle: ReactNode;
  closeIcon: ReactNode;
  disableClose?: boolean;
};

export type ModalHeaderStyles = {
  root: string;
  closeButton: string;
  closeIcon: string;
  title: string;
  textWrapper: string;
};

const ModalHeader: AdjustableComponent<
  Partial<ModalHeaderProps>,
  ModalHeaderStyles
> = ({ disableClose = false, ...props }) => {
  const { classes, title } = props;
  const context = useContext(ModalPropsContext);

  const closeIcon = props.closeIcon || <CrossIcon />;

  const styles = useClasses<ModalHeaderStyles>(
    {
      root: 'Modal__header',
      closeIcon: 'Modal__close-icon',
      closeButton: 'Modal__close-button',
      title: 'Modal__title',
      textWrapper: 'Modal__text-wrapper',
    },
    classes,
  );

  return (
    <div className={styles.root}>
      <div className={styles.textWrapper}>
        {title && <div className={styles.title}>{title}</div>}
      </div>
      {props.children}
      {closeIcon && !disableClose && (
        <button className={styles.closeButton} onClick={context.onRequestClose}>
          <div className={styles.closeIcon}>{closeIcon}</div>
        </button>
      )}
    </div>
  );
};

export default ModalHeader;

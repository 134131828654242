import React, { useRef } from 'react';
import Button from '../Button/Button';
import CarouselButton from './CarouselButton';
import { WordpressContent, WordpressLevel } from '../../features/content/types';
import './Carousel.scss';
import '../../components/List/List.scss';

interface CarouselProps {
  levels: Array<WordpressContent<WordpressLevel>>;
  startLevel: (levelSlug: string) => void;
}

const Carousel: React.FC<CarouselProps> = ({ levels, startLevel }) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const itemWidth = 1135; // in pixels
  const pixelsPerInterval = 15; // decrease for smoother movement
  const intervalDuration = 1; // decrease for faster scrolling

  const scroll = (direction: "left" | "right") => {
    if (!scrollContainerRef.current) {
      return;
    }

    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += direction === "right" ? pixelsPerInterval : -pixelsPerInterval;
        scrollAmount += pixelsPerInterval;
        if (scrollAmount >= itemWidth) {
          window.clearInterval(slideTimer);
        }
      }
    }, intervalDuration);

  };

  return (
    <div className="carousel-container">
      <div className="carousel-content">
        <div className="carousel-background" ref={scrollContainerRef}>
          <CarouselButton direction="left" onClick={() => scroll("left")} />
          <CarouselButton direction="right" onClick={() => scroll("right")} />

          <p>FEATURED DRILLS</p>
          {levels
          .map((level: WordpressContent<WordpressLevel>) => (
            <div className="carousel-buttons" key={level.slug}>
              <Button className="carousel-buttons" onClick={() => startLevel(level.slug)}>
                <div className="carousel-content">
                  <span className="vertical-line"></span>
                    <div className="carousel-text-content"> 
                      <span className="carousel-title">{level.title.rendered}</span>
                      <span className={`carousel-difficulty`} data-difficulty={level.acf.difficulty}>
                        {level.acf.difficulty}
                      </span>
                    </div>
                </div>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Carousel;


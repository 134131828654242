import { useParams } from 'react-router-dom';
import { useFetchData } from '../../common/hooks/useFetchData';
import { Scenario } from '../types';
import { DependencyContainer } from '../../../DependencyContainer';
import { useRecoilState } from 'recoil';
import { scenarioAtom } from '../../../state';

const { scenariosService } = DependencyContainer.getInstance();

export type UseSelectedScenarioValues = [boolean, Scenario | undefined, any];
export const useSelectedScenario = (): UseSelectedScenarioValues => {
  const [scenario, setScenario] = useRecoilState(scenarioAtom);
  const { id } = useParams();
  const [loading, error] = useFetchData<Scenario, Error>(
    () => {
      if (!id) {
        return Promise.reject();
      }
      return scenariosService.getScenario(id);
    },
    (data: Scenario) => setScenario(data),
  );

  return [loading, scenario, error];
};

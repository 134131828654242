import { DependencyContainer } from '../../../DependencyContainer';
import {
  Feedback,
  Report,
  ReportPlayerAssessment,
  ReportTimeLineEvent,
} from '../types';
import { HttpClient } from '../../../HttpClient';
import { AxiosResponse } from 'axios';
import { ApiUrls } from '../../../config/urls';
import { getApiUrl } from '../../../config/environment';

const configOverride = {
  baseURL: getApiUrl(),
};

export default class ReportsClient extends HttpClient {
  constructor(protected readonly factory: DependencyContainer) {
    super(factory);
  }

  fetchReports(token: string): Promise<AxiosResponse<Report[]>> {
    this.setToken(token);
    return this.get<Report[]>(ApiUrls.getUrlForReports(), configOverride);
  }

  fetchUnapprovedReports(
    userEmail: string,
    token: string,
  ): Promise<AxiosResponse<Report[]>> {
    this.setToken(token);
    return this.get<Report[]>(
      ApiUrls.getUrlForUnapprovedReports(userEmail),
      configOverride,
    );
  }

  fetchReport(id: number, token: string): Promise<AxiosResponse<Report>> {
    this.setToken(token);
    return this.get<Report>(ApiUrls.getUrlForReports(id), configOverride);
  }

  fetchUnapprovedReport(
    userEmail: string,
    id: number,
    token: string,
  ): Promise<AxiosResponse<Report>> {
    this.setToken(token);
    return this.get<Report>(
      ApiUrls.getUrlForUnapprovedReports(userEmail, id),
      configOverride,
    );
  }

  fetchTimeLineEvents(
    reportId: number,
    token: string,
  ): Promise<AxiosResponse<ReportTimeLineEvent[]>> {
    this.setToken(token);
    return this.get<ReportTimeLineEvent[]>(
      ApiUrls.getUrlForReportEvents(reportId),
      configOverride,
    );
  }

  fetchTimeLineEventsForUnapprovedReport(
    userEmail: string,
    reportId: number,
    token: string,
  ): Promise<AxiosResponse<ReportTimeLineEvent[]>> {
    this.setToken(token);
    return this.get<ReportTimeLineEvent[]>(
      ApiUrls.getUrlForUnapprovedReportEvents(userEmail, reportId),
      configOverride,
    );
  }

  fetchPlayerAssessment(
    reportId: number,
    token: string,
  ): Promise<AxiosResponse<ReportPlayerAssessment>> {
    this.setToken(token);
    return this.get<ReportPlayerAssessment>(
      ApiUrls.getUrlForReportPlayerAssessment(reportId),
      configOverride,
    );
  }

  fetchPlayerAssessmentForUnapprovedReport(
    userEmail: string,
    reportId: number,
    token: string,
  ): Promise<AxiosResponse<ReportPlayerAssessment>> {
    this.setToken(token);
    return this.get<ReportPlayerAssessment>(
      ApiUrls.getUrlForUnapprovedReportPlayerAssessment(userEmail, reportId),
      configOverride,
    );
  }

  fetchPlayerFeedback(
    reportId: number,
    token: string,
  ): Promise<AxiosResponse<Feedback[]>> {
    this.setToken(token);
    return this.get<Feedback[]>(
      ApiUrls.getUrlForReportPlayerFeedback(reportId),
      configOverride,
    );
  }

  fetchPlayerFeedbackForUnapprovedReport(
    userEmail: string,
    reportId: number,
    token: string,
  ): Promise<AxiosResponse<Feedback[]>> {
    this.setToken(token);
    return this.get<Feedback[]>(
      ApiUrls.getUrlForUnapprovedReportPlayerFeedback(userEmail, reportId),
      configOverride,
    );
  }

  async toggleReport(
    userEmail: string,
    reportId: number,
    token: string,
  ): Promise<void> {
    this.setToken(token);
    await this.patch(
      ApiUrls.getUrlForTogglingReports(userEmail, reportId),
      {},
      configOverride,
    );
  }
}

import { DependencyContainer } from '../../../DependencyContainer';
import { Scenario, ScenarioLevel } from '../types';
import { MqttStaticTopics, MqttTopics } from '../../common/mqtt-topics';
import { GamePlayTopicResponse } from '../../../state';
import { User } from '../../common/hooks/useUser';
import { ScenariosFields, WordpressContent } from '../../content/types';
import { ReadyToPlay, Session } from '../../reports/types';

export default class ScenariosService {
  constructor(private readonly factory: DependencyContainer) { }

  private hydrateScenarioWithContent(
    scenario: Scenario,
    content: WordpressContent<ScenariosFields>,
  ) {
    return {
      title: content.title?.rendered || scenario.title,
      outageCostPerMinute:
        content.acf.outage_cost_per_minute || scenario.outageCostPerMinute,
      difficulty: parseInt(content.acf.difficulty) || scenario.difficulty,
      content: content.acf,
    };
  }

  async getScenarios(): Promise<Scenario[]> {
    const data = await this.factory.scenariosClient.fetchScenarios();

    try {
      const content = await this.factory.contentService.getScenarios();
      return data.map((scenario: Scenario) => {
        const contentForScenario = content.find((c) => c.slug === scenario.id);
        if (!contentForScenario) {
          return scenario;
        }
        const extraContent = this.hydrateScenarioWithContent(
          scenario,
          contentForScenario,
        );
        return {
          ...scenario,
          ...extraContent,
        };
      });
    } catch (error) {
      return data;
    }
  }

  async getScenario(id: string): Promise<Scenario> {
    const scenario = await this.factory.scenariosClient.fetchScenario(id);
    try {
      const content = await this.factory.contentService.getScenarioBySlug(id);
      if (!content) {
        return scenario;
      }
      const extraContent = this.hydrateScenarioWithContent(scenario, content);
      return {
        ...scenario,
        ...extraContent,
        wordpressContentId: content.id,
      };
    } catch (error) {
      console.error(error);
      return scenario;
    }
  }

  async getLevelOfScenario(
    scenario: Scenario,
    levelId: string,
  ): Promise<ScenarioLevel> {
    const level = scenario.levels.find((l) => l.level === parseInt(levelId));
    if (!level) {
      return Promise.reject('Level not found');
    }
    return level;
  }

  /**
   * Currently automation of messages won't be started automatically.
   * Leaving this logic for the future.
   */
  startScenario(scenario: Scenario, playerUID: string, playerEmail: string, level: number): void {
    const startSequencePayload = {
      player_uid: playerUID,
      player: playerEmail,
      scenario: scenario.id,
      level,
      sequence: 1,
    };
    this.factory.mqttService.publish({
      topic: MqttStaticTopics.START_SEQUENCE,
      payload: startSequencePayload,
    });
  }

  getGameStatus(
    user: User,
    onMessage: (message: GamePlayTopicResponse) => void,
  ): () => void {
    const topic = MqttTopics.getGameStatusTopic(user.id);
    this.factory.mqttService.subscribe(topic, onMessage);

    return () => {
      this.factory.mqttService.unsubscribe(topic);
    };
  }

  joinSession(
    user: User,
    sessionId: number,
    scenarioId: string,
    level: number,
    durationInSeconds?: number,
  ): () => void {
    const topic = MqttTopics.getSetStatusTopic();
    this.factory.mqttService.publish({
      topic: MqttStaticTopics.UPDATE_GAME_STATUS,
      payload: {
        player_uid: user.id,
        player: user.email,
        scenario: scenarioId,
        session_id: sessionId,
        level: level.toString(),
        tenant: user.tenant,
        type: 'JOIN_SESSION',
        game_length: durationInSeconds?.toString(),
      },
    });

    console.log('Joining session', sessionId, scenarioId, level);

    return () => {
      this.factory.mqttService.unsubscribe(topic);
    };
  }


  setPlayer(
    user: User,
    scenarioId: string,
    level: number,
    durationInSeconds?: number,
  ): () => void {
    const topic = MqttTopics.getSetStatusTopic();
    this.factory.mqttService.publish({
      topic: MqttStaticTopics.UPDATE_GAME_STATUS,
      payload: {
        player_uid: user.id,
        player: user.email,
        scenario: scenarioId,
        level: level.toString(),
        tenant: user.tenant,
        type: 'new_player',
        game_length: durationInSeconds?.toString(),
      },
    });

    return () => {
      this.factory.mqttService.unsubscribe(topic);
    };
  }

  subscribeToSlackChannel(playerEmail: string, channelName: string): void {
    this.factory.mqttService.publish({
      topic: MqttTopics.getInviteUserToSlackChannel(),
      payload: {
        username: playerEmail,
        channel: channelName,
      },
    });
  }

  async getReadyToPlay(
    token: string
  ): Promise<ReadyToPlay> {
    let response;
    response = await this.factory.scenariosClient.fetchReadyToPlay(token)
    return response.data;
  }

  async getReadySession(
    token: string
  ): Promise<ReadyToPlay> {
    let response;
    response = await this.factory.scenariosClient.fetchReadyToPlay(token)
    return response.data;
  }

  async getSessionByEmail(
    token: string,
    email: string
  ): Promise<Session> {
    let response;
    response = await this.factory.scenariosClient.fetchSessionByEmail(token, email)
    return response.data;
  }
}

import { FunctionComponent } from 'react';
import PendingContent, {
  PendingContentStyles,
} from '../../../../components/PendingContent/PendingContent';
import { useRecoilValue } from 'recoil';
import { awardsAtom, awardsLoadingAtom } from '../../../../state';
import SpinnerLoader from '../../../../components/SpinnerLoader/SpinerLoader';
import Table from '../../../../components/Table/Table';
import TableRow from '../../../../components/Table/TableRow';
import TableColumn from '../../../../components/Table/TableColumn';
import TableHead from '../../../../components/Table/TableHead';
import TableHeadColumn from '../../../../components/Table/TableHeadColumn';
import TableBody from '../../../../components/Table/TableBody';
import Button from '../../../../components/Button/Button';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useBearerToken } from '../../../common/hooks/useBearerToken';
import { capitalize } from '../../../common/helpers/text.helper';

const { awardService } = DependencyContainer.getInstance();

const Certificates: FunctionComponent = () => {
  const awards = useRecoilValue(awardsAtom);
  // const user = useUser();
  const awardsLoading = useRecoilValue(awardsLoadingAtom);
  const token = useBearerToken();

  const generateLinkedinUrl = (award: any) => {
    const url = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
      award.topic
    }&organizationId=79404536&issueYear=${award.created_at.getFullYear()}&issueMonth=${
      award.created_at.getMonth() + 1
    }&expirationYear=${award.expiry_date.getFullYear()}&expirationMonth=${
      award.expiry_date.getMonth() + 1
    }`;
    return url;
  };

  const downloadCert = async (award: any) => {
    if (token) {
      const res = await awardService.generateCertificate(token, award);

      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/pdf' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.download = 'certificate.pdf';
      link.click();
      link.parentNode?.removeChild(link);
    }
  };

  const addToLinkedin = async (award: any) => {
    const url = generateLinkedinUrl(award);
    window.open(url, '_blank');
  };

  return (
    <PendingContent
      classes={(current: PendingContentStyles) => ({
        ...current,
        root: `${current.root} AccountContainer__reports-loading`,
      })}
      loading={awardsLoading}
      hideContent
      loader={SpinnerLoader}
    >
      <div className="AccountContainer__reports-wrapper">
        {awards && Number(awards.length) === 0 && (
          <p>You don't have any awards yet.</p>
        )}

        {awards && Number(awards.length) > 0 && (
          <Table>
            <TableHead>
              <TableHeadColumn>Topic</TableHeadColumn>
              <TableHeadColumn>Level</TableHeadColumn>
              <TableHeadColumn>Date Achieved</TableHeadColumn>
              <TableHeadColumn>Expiry Date</TableHeadColumn>
            </TableHead>
            <TableBody>
              {awards.results.map((award, index) => (
                <TableRow key={index}>
                  <TableColumn>{capitalize(award.topic)}</TableColumn>
                  <TableColumn>{capitalize(award.level)}</TableColumn>
                  <TableColumn>{`${award.created_at.toDateString()}`}</TableColumn>
                  <TableColumn>{`${award.expiry_date.toDateString()}`}</TableColumn>
                  <TableColumn>
                    <Button
                      size="small"
                      variant="secondary"
                      onClick={() => downloadCert(award)}
                    >
                      Download
                    </Button>
                  </TableColumn>
                  <TableColumn>
                    <Button
                      size="small"
                      variant="secondary"
                      onClick={() => addToLinkedin(award)}
                    >
                      Add to LinkedIn
                    </Button>
                  </TableColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </PendingContent>
  );
};

export default Certificates;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DependencyContainer } from '../../../DependencyContainer';
import {
  MediaDetails,
  Page,
  ScenarioFieldsWithFounder,
  ScenariosFields,
  StaffFields,
  StaffWithImageUrl,
  WordpressContent,
  WordpressLevel,
} from '../types';
import { Maybe } from '../../common/types';

export default class ContentService {
  constructor(private readonly factory: DependencyContainer) {}

  private async hydrateScenarioWithFounderData(
    scenario: WordpressContent<ScenariosFields>,
    founderId: number,
  ) {
    const founder = await this.getStaffById(founderId);
    return {
      ...scenario,
      acf: {
        ...scenario.acf,
        founderData: founder,
      },
    };
  }

  async getScenarios(): Promise<
    Array<WordpressContent<ScenarioFieldsWithFounder>>
  > {
    const scenarios = await this.factory.contentClient.fetchScenarios();
    const promises = scenarios.map(
      async (scenario: WordpressContent<ScenariosFields>) => {
        const founderId = parseInt(scenario.acf.founder[0]);
        return this.hydrateScenarioWithFounderData(scenario, founderId);
      },
    );
    return Promise.all(promises);
  }

  async getScenarioBySlug(
    slug: string,
  ): Promise<Maybe<WordpressContent<ScenarioFieldsWithFounder>>> {
    const response = await this.factory.contentClient.fetchScenarios(slug);
    const scenario = response[0];
    const founderId = parseInt(scenario.acf.founder[0]);
    return await this.hydrateScenarioWithFounderData(scenario, founderId);
  }

  async getMedia(mediaId: number): Promise<MediaDetails> {
    const response = await this.factory.contentClient.fetchMedia(mediaId);
    return {
      file: response.data.media_details.file,
      filesize: response.data.media_details.filesize,
      height: response.data.media_details.height,
      image_meta: response.data.media_details.image_meta,
      sizes: response.data.media_details.sizes,
      width: response.data.media_details.width,
      source_url: response.data.source_url,
    };
  }

  async getStaff(): Promise<StaffWithImageUrl[]> {
    const response = await this.factory.contentClient.fetchStaff();
    const promises = response.data.map(
      async (staff: WordpressContent<StaffFields>) => {
        const image = await this.getMedia(staff.acf.picture);

        return {
          name: staff.title.rendered,
          slug: staff.slug,
          picture: staff.acf.picture,
          position: staff.acf.position,
          description: staff.acf.description,
          scenario: staff.acf.scenario,
          imageUrl: image.sizes.full.source_url,
          deutschebankposition: staff.acf.deutschebankposition,
        };
      },
    );
    return await Promise.all(promises);
  }

  async getStaffById(id: number): Promise<StaffWithImageUrl> {
    const response = await this.factory.contentClient.fetchStaff(id);
    const person =
      response.data as unknown as WordpressContent<StaffWithImageUrl>;
    if (!person) {
      throw new Error('Staff not found');
    }
    const image = await this.getMedia(person.acf.picture);
    return {
      name: person.title.rendered,
      slug: person.slug,
      picture: person.acf.picture,
      position: person.acf.position,
      description: person.acf.description,
      scenario: person.acf.scenario,
      imageUrl: image.sizes.full.source_url,
      deutschebankposition: person.acf.deutschebankposition,
    };
  }

  async getPageBySlug(slug: string): Promise<WordpressContent<Page>> {
    const response = await this.factory.contentClient.fetchPage(slug);
    const page = response.data[0];
    return page;
  }

  async getLevels(
    scenarioId: string,
  ): Promise<Array<WordpressContent<WordpressLevel>>> {
    // @TODO: Implement filtering data server side (api)
    const response = await this.factory.contentClient.fetchLevels();
    const levels = response.data
      .filter((d) => d.acf.scenario !== parseInt(scenarioId))
      .sort((a, b) => a.acf.level_id - b.acf.level_id);
    const promises = levels.map(async (level) => {
      if (!level.acf.audio) {
        return level;
      }
      const audio = await this.getMedia(level.acf.audio);
      return {
        ...level,
        acf: {
          ...level.acf,
          // @ts-ignore
          audioUrl: audio.source_url,
        },
      };
    });
    return Promise.all(promises);
  }

  async getLevelById(id: number): Promise<WordpressContent<WordpressLevel>> {
    const response = await this.factory.contentClient.fetchLevels();
    const index = response.data.findIndex((d) => d.acf.level_id === id);
    const level = response.data[index];
    if (!level?.acf.audio) {
      return level;
    }
    const audio = await this.getMedia(level.acf.audio);
    return {
      ...level,
      acf: {
        ...level.acf,
        audioUrl: audio.source_url,
      },
    };
  }

  async getLevelBySlug(
    slug: string,
  ): Promise<WordpressContent<WordpressLevel>> {
    const response = await this.factory.contentClient.fetchLevels(slug);
    const level = response.data[0];
    if (!level?.acf.audio) {
      return level;
    }
    const audio = await this.getMedia(level.acf.audio);
    return {
      ...level,
      acf: {
        ...level.acf,
        // @ts-ignore
        audioUrl: audio.source_url,
      },
    };
  }
}

import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';
import cx from 'classnames';

export type HeroStatProps = {
  value: number | string;
  variant?: 'standard' | 'danger';
  label?: string;
} & JSX.IntrinsicElements['div'];

export type HeroStatStyles = {
  root: string;
  label: string;
  value: string;
};

const HeroStat: AdjustableComponent<HeroStatProps, HeroStatStyles> = ({
  classes,
  label,
  value,
  variant = 'standard',
  ...rest
}) => {
  const styles = useClasses(
    {
      root: cx('HeroStat', {
        [`HeroStat--${variant}`]: true,
      }),
      label: 'HeroStat__label',
      value: 'HeroStat__value',
    },
    classes,
  );
  return (
    <div className={styles.root} {...rest}>
      <div className={styles.value}>{value}</div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

export default HeroStat;

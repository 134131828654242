import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

export type TableRowStyles = {
  root: string;
};

const TableRow: AdjustableComponent<any, TableRowStyles> = (props) => {
  const styles = useClasses(
    {
      root: 'Table__row',
    },
    props.classes,
  );

  return <tr className={styles.root}>{props.children}</tr>;
};

export default TableRow;

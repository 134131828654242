import { DependencyContainer } from '../../../DependencyContainer';
import { Scenario } from '../types';
import mockedScenarios from '../mocks/mocked-scenarios';
import { HttpClient } from '../../../HttpClient';
import { AxiosResponse } from 'axios';
import { ApiUrls } from '../../../config/urls';
import { getApiUrl } from '../../../config/environment';
import { ReadyToPlay, Session } from '../../reports/types';


const configOverride = {
  baseURL: getApiUrl(),
};



export default class ScenariosClient extends HttpClient {
  constructor(protected readonly factory: DependencyContainer) {
    super(factory);
  }

  fetchScenarios(): Promise<Scenario[]> {
    return new Promise<Scenario[]>((resolve) => {
      setTimeout(() => {
        resolve(mockedScenarios);
      }, 500);
    });
  }

  fetchScenario(id: string): Promise<Scenario> {
    const mockedScenario = mockedScenarios.find(
      (scenario) => scenario.id.toLowerCase() === id.toLowerCase(),
    );
    return new Promise<Scenario>((resolve, reject) => {
      setTimeout(() => {
        if (mockedScenario) {
          resolve(mockedScenario);
        } else {
          reject(new Error('Scenario not found'));
        }
      }, 300);
    });
  }

  fetchReadyToPlay(
    token: string
  ): Promise<AxiosResponse<ReadyToPlay>> {
    this.setToken(token);
    return this.get<ReadyToPlay>(ApiUrls.getUrlForReadyToPlay(), configOverride);
  
  }
  fetchSessionByEmail(
    token: string,
    email: string
  ): Promise<AxiosResponse<Session>> {
    this.setToken(token);
    return this.get<Session>(ApiUrls.getUrlForSessionByEmail(email), configOverride);
  }
}

import { KeycloakTokenParsed } from 'keycloak-js';

export const makeInitials = (displayName: string) => {
  const initials = displayName.split(' ').map((n) => n[0]);
  const [a, b, c] = initials;
  return [a, b, c].join('').toUpperCase();
};

export const getDisplayNameFromToken = (
  parsedToken: KeycloakTokenParsed | undefined,
) => {
  if (!parsedToken) {
    return 'Unknown';
  }
  if (parsedToken.given_name && parsedToken.family_name) {
    return `${parsedToken.given_name} ${parsedToken.family_name}`;
  } else if (parsedToken.name) {
    return parsedToken.name;
  } else {
    return parsedToken.preferred_username;
  }
};

export const stripEmojis = (input: string) =>
  input
    .replace(
      new RegExp(
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
        'g',
      ),
      '',
    )
    .trim();

export const makeTextExcerpt = (
  text: string,
  length: number,
  suffix: string = '…',
) => (text.length > length ? text.substring(0, length) + suffix : text);

const avatarColors = [
  '#00AA55',
  '#009FD4',
  '#B381B3',
  '#939393',
  '#E3BC00',
  '#D47500',
  '#DC2A2A',
];

export const colorFromText = (text: string) => {
  const charCodes = text
    .split('')
    .map((char) => char.charCodeAt(0))
    .join('');
  const number = parseInt(charCodes, 10);
  return avatarColors[number % avatarColors.length];
};

export const capitalize = (text: string): string => {
  return text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Tabs, { TabsStyles } from '../../../../components/Tabs/Tabs';
import Profile from '../Profile/Profile';
import Reports from '../Reports/Reports';
import Support from '../Support/Support';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../../../config/routes';
import { useNavigate } from 'react-router';
import Certificates from '../Achievements/Certificates';

type DashboardTab = {
  labelMessage: string;
  content: ReactElement;
  slug: 'profile' | 'reports' | 'achievements' | 'support' | 'logout';
};

const tabs: Array<DashboardTab> = [
  {
    labelMessage: 'Profile',
    content: <Profile />,
    slug: 'profile',
  },
  {
    labelMessage: 'Reports',
    content: <Reports />,
    slug: 'reports',
  },
  {
    labelMessage: 'Achievements',
    content: <Certificates />,
    slug: 'achievements',
  },
  {
    labelMessage: 'Support',
    content: <Support />,
    slug: 'support',
  },
  {
    labelMessage: 'Logout',
    content: <></>,
    slug: 'logout',
  },
];

const navigateMapper: Record<string, AppRoutes> = {
  profile: AppRoutes.Account,
  reports: AppRoutes.Reports,
  achievements: AppRoutes.Achievements,
};

const AdvancedDashboard: FunctionComponent = () => {
  const { keycloak } = useKeycloak();
  const [activeTab, setActiveTab] = useState<DashboardTab['slug']>('reports');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === AppRoutes.Reports) {
      setActiveTab('reports');
    } else if (pathname === AppRoutes.Achievements) {
      setActiveTab('achievements');
    } else {
      setActiveTab('profile');
    }
  }, [pathname]);

  const onLogout = async () => {
    await keycloak.logout({
      redirectUri: window.location.origin,
    });
  };

  return (
    <>
      <Tabs
        classes={(current: TabsStyles) => ({
          ...current,
          content: `${current.content} AccountContainer__tab-content`,
        })}
        onSelectTab={async (option, index) => {
          if (option.id === 'logout') {
            await onLogout();
            return;
          }
          if (['reports', 'profile', 'achievements'].includes(option.id)) {
            const route = navigateMapper[option.id as DashboardTab['slug']];
            navigate(route);
          }
          setActiveTab(option.id as DashboardTab['slug']);
        }}
        options={tabs.map((option: DashboardTab) => ({
          id: option.slug,
          label: option.labelMessage,
          content: option.content,
        }))}
        active={tabs.findIndex((option) => option.slug === activeTab)}
      />
    </>
  );
};

export default AdvancedDashboard;

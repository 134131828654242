import React, { useEffect, useState } from 'react';
import StaffCard, { StaffCardPerson } from '../components/StaffCard/StaffCard';
import { Edge, Node } from 'react-flow-renderer';
import staff from '../components/introductions/online-boutique/staff-structure';
import { useKeycloak } from '@react-keycloak/web';
import { getDisplayNameFromToken } from '../../common/helpers/text.helper';
import Avatar from '../../../components/Avatar/Avatar';
import { PlayerRoles } from '../../common/types';
import { useRecoilState, useRecoilValue } from 'recoil';
import { playerRoleSelector, staffAtom } from '../../../state';
import { StaffWithImageUrl } from '../../content/types';
import { DependencyContainer } from '../../../DependencyContainer';
import { getStaffCardPersonBySlug } from '../../common/helpers/content.helper';

const edgesCommonProps: Partial<Edge> = {
  animated: true,
};

const edges: Edge[] = [
  { id: 'e1-2', source: '1', target: '2', ...edgesCommonProps },
  { id: 'e1-3', source: '3', target: '1', ...edgesCommonProps },
  { id: 'e2-4', source: '2', target: '4', ...edgesCommonProps },
  { id: 'e2-5', source: '2', target: '5', ...edgesCommonProps },
  { id: 'e2-6', source: '2', target: '6', ...edgesCommonProps },
  { id: 'e2-7', source: '2', target: '7', ...edgesCommonProps },
  { id: 'e2-8', source: '2', target: '8', ...edgesCommonProps },
];

const nodeCommonProps: Partial<Node> = {
  draggable: false,
  dragging: false,
  style: { width: '140px', backgroundColor: 'transparent' },
};

const { contentService } = DependencyContainer.getInstance();

const useOnlineBoutiqueStaffStructure = () => {
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);
  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);
  const [selectedPerson, setSelectedPerson] = useState<StaffCardPerson>();
  const { keycloak } = useKeycloak();
  const displayName = getDisplayNameFromToken(keycloak.tokenParsed);
  const playerRole = useRecoilValue(playerRoleSelector);
  const [staffData, setStaffData] = useRecoilState(staffAtom);
  const playerCardProps = {
    image: <Avatar fullName={displayName} />,
    name: displayName,
  };

  useEffect(() => {
    if (staffData.length > 0) {
      return;
    }
    contentService
      .getStaff()
      .then((staff: StaffWithImageUrl[]) => {
        setStaffData(staff);
      })
      .catch(() => {
        console.error('Error loading staff');
      });
  }, []);

  const getPlatformTeam = () => {
    if (playerRole === PlayerRoles.SiteReliabilityEngineer) {
      return {
        ...people.tanya,
        ...playerCardProps,
        position: 'Site Reliability Engineer',
        deutschebankposition: 'Site Reliability Engineer',
        description: `Tasked with ensuring the seamless functioning of our systems, our SRE combines expertise in software development and system administration. With a focus on reliability, scalability, and performance, they play a vital role in maintaining and improving the stability of our digital infrastructure.`,
      };
    }
    return people.tanya;
  };

  const getIncidentManager = () => {
    if (playerRole === PlayerRoles.IncidentManager) {
      return {
        ...people.phil,
        ...playerCardProps,
      };
    }
    return people.phil;
  };

  const people = {
    bez: getStaffCardPersonBySlug('bez-jeffos', staffData, staff.BezJeffos),
    tinus: getStaffCardPersonBySlug(
      'tinus-lorvald',
      staffData,
      staff.TinusLorvald,
    ),
    bob: getStaffCardPersonBySlug(
      'bob-the-phone-johnson',
      staffData,
      staff.Bob,
    ),
    shay: getStaffCardPersonBySlug('shay-newbie', staffData, staff.ShayNewbie),
    daniel: getStaffCardPersonBySlug(
      'daniel-barrell',
      staffData,
      staff.DanielBarrell,
    ),
    tanya: getStaffCardPersonBySlug(
      'tanya-clarke',
      staffData,
      staff.TanyaClarke,
    ),
    hamed: getStaffCardPersonBySlug(
      'hamed-silatani',
      staffData,
      staff.HamedSilatani,
    ),
    phil: getStaffCardPersonBySlug(
      'phil-pollins',
      staffData,
      staff.PhilPollins,
    ),
    maya: getStaffCardPersonBySlug('maya-brown', staffData, staff.MayaBrown),
  };

  const nodes: Node[] = [
    {
      id: '1',
      data: {
        label: (
          <StaffCard
            person={people.bez}
            onPersonSelected={(person: StaffCardPerson) =>
              setSelectedPerson(person)
            }
          />
        ),
      },
      position: { x: -250, y: -255 },
      ...nodeCommonProps,
    },
    {
      id: '2',
      data: {
        label: (
          <StaffCard
            person={people.tinus}
            onPersonSelected={(person: StaffCardPerson) =>
              setSelectedPerson(person)
            }
          />
        ),
      },
      position: { x: -250, y: -100 },
      ...nodeCommonProps,
    },
    {
      id: '3',
      data: {
        label: (
          <StaffCard
            person={people.bob}
            onPersonSelected={(person: StaffCardPerson) =>
              setSelectedPerson(person)
            }
          />
        ),
      },
      position: { x: -450, y: -200 },
      ...nodeCommonProps,
    },
    {
      id: '4',
      data: {
        label: (
          <StaffCard
            person={people.shay}
            onPersonSelected={(person: StaffCardPerson) =>
              setSelectedPerson(person)
            }
          />
        ),
      },
      position: { x: -650, y: 50 },
      ...nodeCommonProps,
    },
    {
      id: '5',
      data: {
        label: (
          <StaffCard
            person={people.daniel}
            onPersonSelected={(person: StaffCardPerson) =>
              setSelectedPerson(person)
            }
          />
        ),
      },
      position: { x: -450, y: 70 },
      ...nodeCommonProps,
    },
    {
      id: '6',
      data: {
        label: (
          <StaffCard
            person={getPlatformTeam()}
            onPersonSelected={(person: StaffCardPerson) =>
              setSelectedPerson(person)
            }
          />
        ),
      },
      position: { x: -250, y: 90 },
      ...nodeCommonProps,
    },
    {
      id: '7',
      data: {
        label: (
          <StaffCard
            person={people.hamed}
            onPersonSelected={(person: StaffCardPerson) =>
              setSelectedPerson(person)
            }
          />
        ),
      },
      position: { x: -50, y: 90 },
      ...nodeCommonProps,
    },
    {
      id: '9',
      data: {
        label: (
          <StaffCard
            person={people.maya}
            onPersonSelected={(person: StaffCardPerson) =>
              setSelectedPerson(person)
            }
          />
        ),
      },
      position: { x: 0, y: -90 },
      ...nodeCommonProps,
    },
    {
      id: '8',
      data: {
        label: (
          <StaffCard
            person={getIncidentManager()}
            onPersonSelected={(person: StaffCardPerson) =>
              setSelectedPerson(person)
            }
          />
        ),
      },
      position: { x: 150, y: 60 },
      ...nodeCommonProps,
    },
  ];

  useEffect(() => {
    setInitialNodes(nodes);
    setInitialEdges(edges);
  }, []);

  useEffect(() => {
    setInitialNodes(nodes);
    setInitialEdges(edges);
  }, [playerRole, staffData]);

  return {
    initialNodes,
    initialEdges,
    selectedPerson,
    setSelectedPerson,
  };
};
export default useOnlineBoutiqueStaffStructure;

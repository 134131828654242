export enum Features {
  OnBoarding = 'onBoarding',
  AdminOnlyCarousel = 'AdminOnlyCarousel',
  AdminOnlyList = 'AdminOnlyList',
  CheckReadyToPlay= 'checkReadyToPlay'
}

export const featureFlagsConfiguration: Record<Features, string[]> = {
  [Features.OnBoarding]: [
    'jaroslaw.miazga@interfeo.com',
    'joe@uptimelabs.io',
    'rcoward@weshape.io',
  ],
  [Features.CheckReadyToPlay]: [],
  [Features.AdminOnlyCarousel]: [],
  [Features.AdminOnlyList]: [],
};

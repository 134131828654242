import React, { useContext } from 'react';
import DropdownContext from './DropdownContext';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

type DropdownToggleProps = {};
export type DropdownToggleStyles = {
  root: string;
};

const DropdownToggle: AdjustableComponent<
  DropdownToggleProps,
  DropdownToggleStyles
> = (props) => {
  const context = useContext(DropdownContext);
  const classes = useClasses(
    {
      root: 'Dropdown__toggle',
    },
    props.classes,
  );

  return (
    <button
      type="button"
      className={classes.root}
      onClick={() => context.toggle()}
    >
      {props.children}
    </button>
  );
};

export default DropdownToggle;

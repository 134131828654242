import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';
import { omit } from '../../features/common/helpers/objects.helper';

export type TableStyles = {
  root: string;
};

export type TableProps = JSX.IntrinsicElements['table'];

const Table: AdjustableComponent<TableProps, TableStyles> = (props) => {
  const propsToPass = omit(props, 'className', 'children', 'classes');

  const classes = useClasses(
    {
      root: 'Table',
    },
    props.classes,
  );

  return (
    <table {...propsToPass} className={classes.root}>
      {props.children}
    </table>
  );
};

export default Table;

import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

export type ModalBodyStyles = {
  root: string;
};

const ModalBody: AdjustableComponent<{}, ModalBodyStyles> = (props) => {
  const styles = useClasses<ModalBodyStyles>(
    {
      root: 'Modal__body',
    },
    props.classes,
  );

  return <div className={styles.root}>{props.children}</div>;
};

export default ModalBody;

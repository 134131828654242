import React, { FunctionComponent, useCallback, useRef } from 'react';
import Button from '../../../../components/Button/Button';
import { AppRoutes } from '../../../../config/routes';
import { useRedirectToRoute } from '../../../common/hooks/useRedirectToRoute';
import { useRecoilValue } from 'recoil';
import { gamePlayAtom, levelsAtom } from '../../../../state';
import Confetti from 'react-confetti';
import { Maybe } from '../../../common/types';
import { WordpressContent, WordpressLevel } from '../../../content/types';

const GamePlaySuccess: FunctionComponent = () => {
  const gamePlay = useRecoilValue(gamePlayAtom);
  const goToRoute = useRedirectToRoute();
  const gamePlayRef = useRef<HTMLDivElement>(null);
  const scenarioLevels = useRecoilValue(levelsAtom);

  const getNextLevel = useCallback((): Maybe<
    WordpressContent<WordpressLevel>
  > => {
    const level = parseInt(gamePlay.level);
    if (level > scenarioLevels.length) {
      return;
    }
    const nextLevelId = level + 1;
    const nextLevel = scenarioLevels.find(
      (l) => l.acf.level_id?.toString() === nextLevelId?.toString(),
    );
    if (!nextLevel) {
      return;
    }
    return nextLevel;
  }, [scenarioLevels]);

  const getNextLevelSlug = (): Maybe<string> => {
    const nextLevel = getNextLevel();
    return nextLevel?.slug;
  };

  const getNextLevelDrillName = (): Maybe<string> => {
    const nextLevel = getNextLevel();
    return nextLevel?.title.rendered;
  };

  const goToNextLevel = () => {
    if (!scenarioLevels || scenarioLevels.length === 0) {
      return;
    }
    const nextLevelSlug = getNextLevelSlug();
    if (!nextLevelSlug) {
      return;
    }
    goToRoute(AppRoutes.ScenarioLevel, {
      id: gamePlay.scenario,
      level: nextLevelSlug,
    });
  };

  const goToAllDrills = () => {
    goToRoute(AppRoutes.ScenarioLevels, { id: gamePlay.scenario });
  };

  const getConfettiWidth = () => {
    return gamePlayRef.current?.offsetWidth || window.innerWidth;
  };

  return (
    <div className="GamePlay" ref={gamePlayRef}>
      <Confetti
        recycle={false}
        run
        width={getConfettiWidth()}
        tweenDuration={4000}
      />
      <h3 className="ScenariosContainer__play-scenario-box-title">
        Congratulations, you completed the drill successfully.
      </h3>
      <div className="GamePlay__action-buttons">
        {getNextLevel() ? (
          <Button onClick={() => goToNextLevel()}>
            Play {getNextLevelDrillName()}
          </Button>
        ) : (
          <Button onClick={() => goToAllDrills()}> Back to All Drills</Button>
        )}
        <Button
          className="Button Button--pulse-orange"
          size="small"
          onClick={() => goToRoute(AppRoutes.Reports)}
        >
          View Your Reports
        </Button>
      </div>
    </div>
  );
};

export default GamePlaySuccess;

import React, { FunctionComponent } from 'react';
import {
  LevelStates,
  ReportGenericCardProps,
  ReportTimeLineEvent,
} from '../types';
import Card from '../../../components/Card/Card';
import {
  createTheme,
  Events,
  TextEvent,
  themes,
  Timeline,
} from '@merc/react-timeline';
import { reportTimeLineTheme } from '../../../config/config';
import {
  GameStartedEvent,
  GoalEvent,
  GoalResolvedEvent,
  HintEvent,
  OutOfTimeEvent,
  StarEvent,
  TimeLineEvent,
} from './time-line-components';

export type ReportTimeLineCardProps = {
  timeLineEvents: ReportTimeLineEvent[];
};

const timeLineTheme = createTheme(themes.default, reportTimeLineTheme);

const mappers: Array<{
  predicate: (state: LevelStates) => boolean;
  component: FunctionComponent<TimeLineEvent>;
}> = [
  {
    predicate: (state: LevelStates) =>
      [
        LevelStates.IdentifySeverityHint,
        LevelStates.IncidentRecordHint,
        LevelStates.IdentifyScopeHint,
        LevelStates.HintIdentifyScope,
        LevelStates.HintIdentifySeverity,
        LevelStates.HintIncidentRecord,
      ].includes(state),
    component: HintEvent,
  },
  {
    predicate: (state: LevelStates) => state === LevelStates.Playing,
    component: GameStartedEvent,
  },
  {
    predicate: (state: LevelStates) =>
      [
        LevelStates.Start,
        LevelStates.IdentifyScope,
        LevelStates.CommentsIn5Minutes,
        LevelStates.IncidentRecord,
        LevelStates.StarCommentsIn5Minutes,
        LevelStates.StarIdentifyScope,
        LevelStates.StarIdentifySeverity,
        LevelStates.StarIncidentRecord,
        LevelStates.StarQualifyTheProblem,
      ].includes(state),
    component: GoalEvent,
  },
  {
    predicate: (state: LevelStates) => state === LevelStates.Completed,
    component: GoalResolvedEvent,
  },
  {
    predicate: (state: LevelStates) => state === LevelStates.OutOfTime,
    component: OutOfTimeEvent,
  },
  {
    predicate: (state: LevelStates) => {
      const [descriptor] = state.split('-');
      if (!descriptor) {
        return false;
      }
      return descriptor.toLowerCase() === 'star';
    },
    component: StarEvent,
  },
  {
    predicate: (state: LevelStates) => {
      const [descriptor] = state.split('-');
      if (!descriptor) {
        return false;
      }
      return descriptor.toLowerCase() === 'hint';
    },
    component: HintEvent,
  },
];

const mapStateToGenericMessage: Record<string, string> = {
  [LevelStates.OutOfTime]: 'Out of time',
  [LevelStates.Completed]: 'Game completed',
  [LevelStates.Playing]: 'Game started',
};

const getMapper = (state: LevelStates): FunctionComponent<TimeLineEvent> => {
  return mappers.find((m) => m.predicate(state))?.component || TextEvent;
};

const getEventDescription = (timeLineEvent: ReportTimeLineEvent) => {
  return (
    mapStateToGenericMessage[timeLineEvent.state] ||
    timeLineEvent.description ||
    timeLineEvent.info
  );
};

const ReportTimeLineCard: FunctionComponent<
  ReportGenericCardProps<ReportTimeLineCardProps>
> = ({ timeLineEvents, stylesProvider }) => {
  const renderEvents = () => {
    return (
      <>
        {timeLineEvents.map((event) => {
          const MapperComponent = getMapper(event.state);
          return (
            <MapperComponent
              date={event.time.toLocaleTimeString()}
              time=""
              text={getEventDescription(event)}
            />
          );
        })}
      </>
    );
  };

  return (
    <Card
      collapsible
      expanded={'true'}
      title="Timeline"
      classes={stylesProvider}
    >
      {timeLineEvents && timeLineEvents.length ? (
        <Timeline theme={timeLineTheme}>
          <Events>{renderEvents()}</Events>
        </Timeline>
      ) : (
        <p>
          This report does not contain any events. The most likely the game has
          been abandoned by player.
        </p>
      )}
    </Card>
  );
};

export default ReportTimeLineCard;

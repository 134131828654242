import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

type DropdownContentProps = {};
export type DropdownContentStyles = {
  root: string;
};

const DropdownContent: AdjustableComponent<
  DropdownContentProps,
  DropdownContentStyles
> = (props) => {
  const classes = useClasses(
    {
      root: 'Dropdown__content',
    },
    props.classes,
  );

  return <div className={classes.root}>{props.children}</div>;
};

export default DropdownContent;

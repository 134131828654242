import React, { useEffect } from 'react';
import AdvancedDashboard from '../components/Dashboard/AdvancedDashboard';
import { useFetchData } from '../../common/hooks/useFetchData';
import { DependencyContainer } from '../../../DependencyContainer';
import { useBearerToken } from '../../common/hooks/useBearerToken';
import { Report } from '../../reports/types';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  reportsAtom,
  reportsLoadingAtom,
  reportsSelectedUserEmailSelector,
} from '../../../state';
import { useUser } from '../../common/hooks/useUser';
import {
  awardsAtom,
  awardsLoadingAtom,
} from '../../../state';
import { AwardList } from "../../awards/types";

const { reportsService } = DependencyContainer.getInstance();
const { awardService } = DependencyContainer.getInstance();

const AccountContainer = () => {
  const user = useUser();
  const [reports, setReports] = useRecoilState(reportsAtom);
  const setReportsLoading = useSetRecoilState(reportsLoadingAtom);
  const reportsSelectedUserEmail = useRecoilValue(
    reportsSelectedUserEmailSelector,
  );
  const token = useBearerToken();

  useFetchData<Report[], Error>(
    () => {
      if (!token || !user) {
        return Promise.reject([]);
      }
      setReportsLoading(true);
      const email =
        user.isAdmin && reportsSelectedUserEmail
          ? reportsSelectedUserEmail
          : undefined;
      return reportsService.getReports(token, email);
    },
    (reports) => setReports(reports),
    [token, reportsSelectedUserEmail],
    () => !token || !user,
  );

  useEffect(() => {
    if (reports) {
      setReportsLoading(false);
    }
  }, [reports]);
  
  const [awards, setAwards] = useRecoilState(awardsAtom);
  // const user = useUser();
  const setAwardsLoading = useSetRecoilState(awardsLoadingAtom);
  // const awardsLoading = useRecoilValue(awardsLoadingAtom);

  useFetchData<AwardList, Error>(
      async () => {
        if (!token) {
          return Promise.reject([]);
        }
        setAwardsLoading(true);
        return await awardService.getAwards(token);
      },
      (awards) => setAwards(awards),
      [token],
      () => !token,
    );

    useEffect(() => {
      if (awards) {
        setAwardsLoading(false);
      }
    }, [awards]);
    // if (awards) {
    //   console.log(awards.results);
    // }

  return (
    <div className="AccountContainer">
      <AdvancedDashboard />
    </div>
  );
};

export default AccountContainer;

import { createPersistentState } from './core';
import { Maybe, PlayerRoles } from '../features/common/types';

type InitialState = {
  audiosPlayed: string[];
  tourRan: boolean;
  selectedPlayerRole: Maybe<PlayerRoles>;
  reportsSelectedUserEmail: Maybe<string>;
};

const initialState: InitialState = {
  audiosPlayed: [],
  tourRan: false,
  selectedPlayerRole: undefined,
  reportsSelectedUserEmail: undefined,
};
const persistentStateWrapper = createPersistentState(initialState);

export const {
  persistentState,
  usePersistentState,
  getAtom,
  initializePersistentState,
} = persistentStateWrapper;
export default persistentStateWrapper;

import React, { ReactNode } from 'react';

export const repeatElement = (
  element: ReactNode,
  times: number,
  keyGenerator = (_: ReactNode, index: number) => index.toString(),
) =>
  [...Array(times)].map((it, index) => (
    <React.Fragment key={keyGenerator(it, index)}>{element}</React.Fragment>
  ));

export const isFunctionComponent = (component: any) => {
  return (
    typeof component === 'function' &&
    String(component).includes('return React.createElement')
  );
};

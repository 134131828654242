import { DependencyContainer } from '../../../DependencyContainer';
import { HttpClient } from '../../../HttpClient';
import { AxiosResponse } from 'axios';
import { ApiUrls } from '../../../config/urls';
import { getApiUrl } from '../../../config/environment';
import { AwardList } from '../types';
import { Award } from '../types';
import { ResponseType } from 'axios';


const configOverride = {
    baseURL: getApiUrl(),
  };

export default class AwardClient extends HttpClient {
    constructor(protected readonly factory: DependencyContainer) {
        super(factory);
    }

    async fetchAwards(token: string): Promise<AxiosResponse<AwardList>> {
        this.setToken(token);
        const response = await this.get<AwardList>(ApiUrls.getUrlForAwards(), configOverride);

        response.data.results = response.data.results.map((award) => {
        
            return {
                ...award,
                expiry_date: new Date(award.expiry_date),
                created_at: new Date(award.created_at),
            };
        });
        
        return response;
    }

    async fetchCertificate(token: string, certificate:Award): Promise<AxiosResponse<any>> {
        this.setToken(token);
        const responseType:ResponseType = 'blob';
        const config = {
            baseURL: getApiUrl(),
            params: certificate,
            responseType: responseType,
        }


        return await this.get<AwardList>(ApiUrls.getUrlForGenerateCertificate(), config);
    }
}
import React, { FunctionComponent, useState } from 'react';
import SlackNotificationsInstructionsModal from '../../../common/components/SlackNotificationsInstructionsModal/SlackNotificationsInstructionsModal';
import { NotificationsIcon } from '../../../../components/Icons';
import Button, { ButtonStyles } from '../../../../components/Button/Button';

const GamePlayReady: FunctionComponent = () => {
  const [
    isSlackNotificationsInstructionsModalOpened,
    setIsSlackNotificationsInstructionsModalOpened,
  ] = useState<boolean>(false);
  return (
    <div className="GamePlay">
      <p>
        Your game play will start soon. In the meantime go get familiar with all
        of the tools. Please start with Slack.
      </p>
      <p>
        Make sure you have enabled Slack notifications.
        <Button
          classes={(current: ButtonStyles) => ({
            ...current,
            root: `${current.root} GamePlay__notifications-button`,
          })}
          size="small"
          variant="tertiary"
          onClick={() => setIsSlackNotificationsInstructionsModalOpened(true)}
        >
          <NotificationsIcon width={24} />
          See instructions how to enable notifications
        </Button>
      </p>
      <SlackNotificationsInstructionsModal
        isOpen={isSlackNotificationsInstructionsModalOpened}
        onRequestClose={() =>
          setIsSlackNotificationsInstructionsModalOpened(false)
        }
      />
    </div>
  );
};

export default GamePlayReady;

import React, { createContext } from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

export type ModalProps = ReactModal.Props & {
  fullWidth?: boolean;
  withSeparators?: boolean;
  transparentBackground?: boolean;
  frame?: boolean;
};

export type ModalStyles = {
  root: string;
};

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement(document.createElement('div'));
}

export const ModalPropsContext = createContext<Partial<ModalProps>>({
  onRequestClose: (
    // @ts-ignore
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {},
});

if (process.env.NODE_ENV === 'test') {
  ReactModal.setAppElement(document.createElement('div'));
}

const Modal: AdjustableComponent<ModalProps, ModalStyles> = (props) => {
  const classes = useClasses<ModalStyles>(
    {
      root: cx('Modal', {
        'Modal--full-width': props.fullWidth,
        'Modal--with-separators': props.withSeparators,
        'Modal--transparent-background': props.transparentBackground,
        'Modal--frame': props.frame,
      }),
    },
    props.classes,
  );

  if (!props.isOpen) {
    return null;
  }

  return (
    <ReactModal {...props} className={classes.root}>
      <ModalPropsContext.Provider
        value={{
          ...props,
        }}
      >
        {props.children}
      </ModalPropsContext.Provider>
    </ReactModal>
  );
};

export default Modal;

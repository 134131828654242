import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';
import { omit } from '../../features/common/helpers/objects.helper';

export type TableColumnStyles = {
  root: string;
  inner: string;
};

export type TableColumnProps = JSX.IntrinsicElements['td'];

const TableColumn: AdjustableComponent<TableColumnProps, TableColumnStyles> = (
  props,
) => {
  const propsToPass = omit(props, 'className', 'children', 'classes');

  const styles = useClasses(
    {
      root: 'Table__column',
      inner: 'Table__column-inner',
    },
    props.classes,
  );

  return (
    <td {...propsToPass} className={styles.root}>
      <div className={styles.inner}>{props.children}</div>
    </td>
  );
};

export default TableColumn;

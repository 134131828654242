import React from 'react';
import AudioPlayer, {
  AudioPlayerStyles,
} from '../../../../../components/AudioPlayer/AudioPlayer';
import { useFetchData } from '../../../../common/hooks/useFetchData';
import { Page, WordpressContent } from '../../../../content/types';
import { DependencyContainer } from '../../../../../DependencyContainer';
import PendingContent from '../../../../../components/PendingContent/PendingContent';
import SpinnerLoader from '../../../../../components/SpinnerLoader/SpinerLoader';
import { contentConfiguration } from '../../../../../config/config';

const { contentService } = DependencyContainer.getInstance();

const OnlineBoutiqueIntroductionStep4 = () => {
  const [loading, wordpressPage, error] = useFetchData<
    WordpressContent<Page>,
    Error
  >(() =>
    contentService.getPageBySlug(
      contentConfiguration.OnlineBoutique.introductionStep4ContentSlug,
    ),
  );

  return (
    <div className="OnlineBoutiqueIntroduction">
      <AudioPlayer
        source="/audio/k8_intro.mp3"
        classes={(current: AudioPlayerStyles) => ({
          ...current,
          root: `${current.root} OnlineBoutiqueIntroduction__audio-player`,
        })}
      />
      <PendingContent
        loading={loading}
        isError={Boolean(error)}
        loader={SpinnerLoader}
        hideContent
      >
        {wordpressPage && (
          <div
            className="text-center"
            dangerouslySetInnerHTML={{ __html: wordpressPage.content.rendered }}
          />
        )}
      </PendingContent>
    </div>
  );
};

export default OnlineBoutiqueIntroductionStep4;

import { StaffWithImageUrl } from '../../content/types';
import { StaffCardPerson } from '../../scenarios/components/StaffCard/StaffCard';
import { StaffPerson } from '../../scenarios/components/introductions/online-boutique/staff-structure';

export const getStaffCardPersonBySlug = (
  slug: string,
  staff: StaffWithImageUrl[],
  fallback?: StaffPerson,
): StaffCardPerson => {
  const entry = staff.find((s) => s.slug === slug);
  if (!entry) {
    return {
      image: fallback?.image || '',
      description: fallback?.description || '',
      name: fallback?.name || '',
      position: fallback?.position || '',
      deutschebankposition: fallback?.deutschebankposition || '',
    };
  }
  return {
    image: entry.imageUrl,
    description: entry.description,
    name: entry.name,
    position: entry.position,
    deutschebankposition: entry.deutschebankposition,
  };
};

import { useLocation } from 'react-router-dom';
import { getMatchedRoute } from '../helpers/routes.helper';
import { AppRoutes } from '../../../config/routes';

export type UseCurrentRouteReturnType = () => AppRoutes;

export const useCurrentRoute = (): UseCurrentRouteReturnType => {
  const location = useLocation();

  return (): AppRoutes => {
    try {
      const route = getMatchedRoute(location.pathname);
      return route;
    } catch (error) {
      return AppRoutes.Account;
    }
  };
};

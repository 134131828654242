import React from 'react';
import { Link } from 'react-router-dom';

const IncidentManager: React.FC = () => {
  return (
    <div className="__role-intro">
      <div>
        <h3> Welcome to the Online Boutique family!</h3>
      </div>
      <div>
        <p>
          {' '}
          We're thrilled to have you onboard as our new Incident Manager. Please
          read the guidelines below to gain a better understanding of how we
          expect Incident Management to be carried out here.
        </p>
        <p>
          {' '}
          Familiarise yourself with our ways of working, guided by the
          <Link to={`/learning/312`}> STAR Method </Link>it will be key to your
          success as an Incident Manager at OB.
        </p>
      </div>
      <div>
        <div>
          <h4>Collaboration is Key</h4>
          <p>
            Our Incident Managers play a crucial role in harmonizing efforts by
            working closely with our engineers, creating solutions, and
            coordinating with customer support to set and manage our user
            expectations.
          </p>
        </div>

        <div>
          <h4>Skills for Success</h4>
          <ul>
            <li>
              Effective & Clear Communication: Concise communication is critical
              for coordinating with your team and OB’s stakeholders during
              incident resolution. Ensure you keep everyone informed at all
              stages of the incident response.
            </li>
            <li>
              Taking Control: It's on you as Incident Manager to own the
              incident response. Start getting comfortable with investigating
              issues, delegating tasks, creating incident records, assigning
              severity levels to incidents, and escalating when needed.
            </li>
            <li>
              Problem-Solving: We need you to be able to identify, size up, and
              qualify issues to formulate effective solutions.
            </li>
            <li>
              Crisis Management: We need our Incident Managers to stay calm
              under pressure, make sound decisions in high-stakes situations to
              minimize downtime, and uphold user satisfaction.
            </li>
            <li>
              Technical Proficiency: Understand our tech stack. We use Slack for
              communication and Grafana as our dashboard.
            </li>
            <li>
              Adaptability: Being flexible and adaptable to changing
              circumstances is integral to ensuring smooth collaboration in
              dynamic incident scenarios.
            </li>
          </ul>
        </div>

        <div>
          <p>
            These guidelines should help you triumph against any incident in the
            future. If you have any questions, please ask one of your Online
            Boutique team members for assistance. Good luck in your new role!
          </p>
        </div>
      </div>
    </div>
  );
};

export default IncidentManager;

import { FunctionComponent } from 'react';
import Modal, { ModalStyles } from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody';
import { ExplanatoryVideoConfig } from '../../../../config/config';
import ModalHeader from '../../../../components/Modal/ModalHeader';
import { useMedia } from '../../hooks/useMedia';

export type VideoModalProps = {
  video: ExplanatoryVideoConfig;
  isOpen: boolean;
  onRequestClose: () => void;
};
const VideoModal: FunctionComponent<VideoModalProps> = ({
  video,
  isOpen,
  onRequestClose,
}) => {
  const { sizes } = useMedia();
  const width = !sizes.large ? '100%' : `${video.width}px`;
  const height = `${video.height}px`;
  const containerStyles = {
    width,
    height,
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} VideoModal`,
      })}
    >
      <ModalHeader />
      <ModalBody>
        <div className="VideoModal__body" style={containerStyles}>
          <iframe
            width="100%"
            height="100%"
            src={video.url}
            title={video?.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VideoModal;

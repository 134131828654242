import React, { FunctionComponent } from 'react';
import { Report } from '../types';
import Card, { CardStyles } from '../../../components/Card/Card';
import { ClassesModifier } from '../../common/hooks/useClasses';
import { useKeycloak } from '@react-keycloak/web';
import { getDisplayNameFromToken } from '../../common/helpers/text.helper';
import { availablePlayerRoles } from '../../../config/config';
import cx from 'classnames';
import formatTime from '../../common/helpers/formatTime.helper';
import { useUser } from '../../common/hooks/useUser';
import { useRecoilValue } from 'recoil';
import { reportsSelectedUserEmailSelector } from '../../../state';
import Table from '../../../components/Table/Table';
import TableRow from '../../../components/Table/TableRow';
import TableColumn from '../../../components/Table/TableColumn';
import { useFetchData } from '../../common/hooks/useFetchData';
import { contentService } from '../../scenarios/containers/ScenarioJoinContainer';
import { WordpressContent, WordpressLevel } from '../../content/types';

export type ReportSummaryCardProps = {
  report: Report;
  isIncomplete?: boolean;
  stylesProvider: ClassesModifier<CardStyles>;
};

const ReportSummaryCard: FunctionComponent<ReportSummaryCardProps> = ({
  report,
  isIncomplete = false,
  stylesProvider,
}) => {
  const { keycloak } = useKeycloak();
  const displayName = getDisplayNameFromToken(keycloak.tokenParsed);
  const user = useUser();
  const reportsSelectedUserEmail = useRecoilValue(
    reportsSelectedUserEmailSelector,
  );

  const getPlayerRoleLabel = (): string => {
    return (
      availablePlayerRoles.find((r) => r.name === report?.role)?.label || 'n/a'
    );
  };

  const getPlayerName = () => {
    if (user.isAdmin && reportsSelectedUserEmail) {
      return reportsSelectedUserEmail;
    }
    return displayName;
  };

  const getResolutionTime = () => {
    return report?.session_duration || 0;
  };

  const getReportDate = () => {
    const dateString = report?.session_start?.toLocaleDateString();
    return dateString || null;
  };

  const resolvedTimeClassNames = cx('ReportContainer__resolved-time', {
    'ReportContainer__resolved-time--incomplete': isIncomplete,
  });

  const level = report.level.id;
  const [, levelContent] = useFetchData<
    WordpressContent<WordpressLevel>,
    Error
  >(
    () => {
      return contentService.getLevelById(level);
    },
    undefined,
    [level],
    () => !level,
  );

  const getDrillName = () => {
    return levelContent?.title?.rendered || 'Loading...';
  };

  const summaryTableProperties = [
    {
      label: 'Role',
      value: getPlayerRoleLabel(),
    },
    {
      label: 'Date',
      value: getReportDate(),
    },
    {
      label: 'Scenario',
      value: report.scenario.displayName,
    },
    {
      label: 'Drill',
      value: getDrillName(),
    },
  ];

  return (
    <Card collapsible={false} classes={stylesProvider}>
      <div className="ReportContainer__details-card">
        <h1>{getPlayerName()}</h1>
        <div className="ReportContainer__details-row">
          <div className="ReportContainer__details">
            <Table>
              {summaryTableProperties.map((row) => (
                <TableRow key={row.label}>
                  <TableColumn>
                    <span className="text-gray">{row.label}:</span>
                  </TableColumn>
                  <TableColumn>{row.value}</TableColumn>
                </TableRow>
              ))}
            </Table>
          </div>
          <div className={resolvedTimeClassNames}>
            {isIncomplete ? (
              <h2>Out of time</h2>
            ) : (
              <>
                <h2>{formatTime(getResolutionTime())}</h2>
                Resolution time
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ReportSummaryCard;

import { DependencyContainer } from "../../../DependencyContainer";
import { AwardList } from "../types";
import { Award } from "../types";

export default class AwardService {
    constructor(private readonly factory: DependencyContainer) {}

    async getAwards(token: string): Promise<AwardList> {

        const certificats = await this.factory.awardClient.fetchAwards(
            token,
        );

        return certificats.data;
    }
    async generateCertificate(token: string, certificate:Award): Promise<any> {

        return await this.factory.awardClient.fetchCertificate(
            token,
            certificate
        );
    }
}
import React, { ReactElement } from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../../common/hooks/useClasses';
import { useUser } from '../../../common/hooks/useUser';

export type StaffCardPerson = {
  name: string;
  position: string;
  image: string | ReactElement;
  description: string;
  deutschebankposition?: string;
};

export type StaffCardProps = {
  person: StaffCardPerson;
  onPersonSelected?: (person: StaffCardPerson) => void;
} & JSX.IntrinsicElements['div'];
export type StaffCardStyles = {
  root: string;
  people: string;
  person: string;
  fullName: string;
  position: string;
  image: string;
  deutschebankposition?: string;
};

const StaffCard: AdjustableComponent<StaffCardProps, StaffCardStyles> = ({
  classes,
  person,
  onPersonSelected,
  ...rest
}) => {
  const styles = useClasses(
    {
      root: 'StaffCard',
      fullName: 'StaffCard__full-name',
      position: 'StaffCard__position',
      people: 'StaffCard__people',
      person: 'StaffCard__person',
      image: 'StaffCard__image',
      deutschebankposition: 'StaffCard__position',
    },
    classes,
  );

  const deutscheBankPlayer = useUser().roles.includes('tenant-deutschebank');

  const onPersonClick = (person: StaffCardPerson) => {
    onPersonSelected?.(person);
  };

  return (
    <div className={styles.root} {...rest}>
      <div onClick={() => onPersonClick(person)} className={styles.person}>
        <div className={styles.image}>
          {typeof person.image === 'string' ? (
            <img src={person.image} alt={person.name} />
          ) : (
            person.image
          )}
        </div>
        <h2 className={styles.fullName}>{person.name}</h2>
        <p className={styles.position}>
          {deutscheBankPlayer
            ? person.deutschebankposition || person.position
            : person.position}
        </p>
      </div>
    </div>
  );
};

export default StaffCard;

import React, { FunctionComponent } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import './styles/root.scss';
import AuthClient from './features/common/AuthClient';
import ForAuthenticated from './features/common/helpers/ForAuthenticated';
import AccountContainer from './features/account/containers/AccountContainer';
import { AppRoutes } from './config/routes';
import ScenariosContainer from './features/scenarios/containers/ScenariosContainer';
import AppLayout from './layouts/AppLayout';
import PlayScenarioContainer from './features/scenarios/containers/PlayScenarioContainer';
import { RecoilRoot } from 'recoil';
import ScenarioLevelContainer from './features/scenarios/containers/ScenarioLevelContainer';
import ScenarioJoinContainer from './features/scenarios/containers/ScenarioJoinContainer';
import ScenarioLevelChooserContainer from './features/scenarios/containers/ScenarioLevelChooserContainer';
import ScenarioIntroductionContainer from './features/scenarios/containers/ScenarioIntroductionContainer';
import PendingAccountContainer from './features/account/containers/PendingAccountContainer';
import ForActivatedAccount from './features/common/helpers/ForActivatedAccount';
import PersistentStateRoot from './persistent-state/PersistentStateRoot';
import { initializePersistentState } from './persistent-state/persistent-state';
import IndexContainer from './features/index/containers/IndexContainer';
import VersionContainer from './features/version/containers/VersionContainer';
import ReportContainer from './features/account/containers/ReportContainer';
import ProxyErrorContainer from './features/error/ProxyErrorContainer';
import LearningCenter from './features/learning/LearningCenter';
import Post from './features/learning/Post';
import LobbyJoinContainer from './features/scenarios/components/Lobby/lobbyJoinContainer';
const App: FunctionComponent = () => {
  return (
    <ReactKeycloakProvider authClient={AuthClient}>
      <RecoilRoot
        initializeState={({ set }) => {
          initializePersistentState(set);
        }}
      >
        <PersistentStateRoot>
          <BrowserRouter>
            <Routes>
              <Route
                path={AppRoutes.Index}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <IndexContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.AccountWithTabs}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <AccountContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.Account}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <AccountContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.Reports}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <AccountContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.InactiveAccount}
                element={
                  <ForAuthenticated>
                    <AppLayout>
                      <PendingAccountContainer />
                    </AppLayout>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.Scenario}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <ScenarioIntroductionContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.ScenarioLevel}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <ScenarioLevelContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.ScenarioJoin}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <ScenarioJoinContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.LobbyJoin}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <LobbyJoinContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.ScenarioLevels}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <ScenarioLevelChooserContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.Scenarios}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <ScenariosContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.PlayScenario}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <PlayScenarioContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.Version}
                element={
                  <AppLayout>
                    <VersionContainer />
                  </AppLayout>
                }
              />
              <Route
                path={AppRoutes.Report}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <ReportContainer />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.ProxyError}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <ProxyErrorContainer />
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              <Route
                path={AppRoutes.LearningCenter}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <LearningCenter />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
              import Post from './Post';
              <Route
                path={AppRoutes.LearningCenterContent}
                element={
                  <ForAuthenticated>
                    <ForActivatedAccount>
                      <AppLayout>
                        <Post />
                      </AppLayout>
                    </ForActivatedAccount>
                  </ForAuthenticated>
                }
              />
            </Routes>
          </BrowserRouter>
        </PersistentStateRoot>
      </RecoilRoot>
    </ReactKeycloakProvider>
  );
};

export default App;

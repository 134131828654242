import { Scenario, ScenarioIntroductionStepDefinition } from '../types';
import React, { ReactElement, useState } from 'react';
import Button, { ButtonStyles } from '../../../components/Button/Button';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import { AppRoutes } from '../../../config/routes';

export type IntroductionStepAction = {
  label: string;
  onClick: () => void;
  isAvailable: boolean;
};

export type IntroductionPlayerValues = {
  renderedStep: ReactElement | null;
  actions: ReactElement;
  step?: number;
};

const addStylesToActionButton = (current: ButtonStyles) => ({
  ...current,
  root: `${current.root} IntroductionPlayer__action`,
});

const useIntroductionPlayer = (
  steps: ScenarioIntroductionStepDefinition[],
  scenario: Scenario,
  step = 1,
): IntroductionPlayerValues => {
  const [currentStep, setCurrentStep] = useState<number>(steps[step].step);
  const goToRoute = useRedirectToRoute();
  const RenderedStep = steps.find((s) => s.step === currentStep)?.component;

  const actions: IntroductionStepAction[] = [
    {
      label: 'Go back',
      onClick: () => {
        goBackToScenarios();
      },
      isAvailable: currentStep === 1,
    },
    {
      label: 'Previous',
      onClick: () => {
        goToPreviousStep();
      },
      isAvailable: currentStep > 1,
    },
    {
      label: 'Skip introduction',
      onClick: () => {
        goToLevelSelection();
      },
      isAvailable: currentStep < steps.length,
    },
    {
      label: 'Next',
      onClick: () => {
        goToNextStep();
      },
      isAvailable: currentStep < steps.length,
    },
    {
      label: 'Next',
      onClick: () => {
        goToLevelSelection();
      },
      isAvailable: currentStep === steps.length,
    },
  ];

  const goBackToScenarios = () => {
    if (currentStep > 1) {
      return;
    }
    goToRoute(AppRoutes.Scenarios);
  };

  const goToLevelSelection = () => {
    goToRoute(AppRoutes.ScenarioLevels, {
      id: scenario.id,
    });
  };

  const goToNextStep = () => {
    if (currentStep >= steps.length) {
      return;
    }
    setCurrentStep((step) => step + 1);
  };

  const goToPreviousStep = () => {
    if (currentStep <= 1) {
      return;
    }
    setCurrentStep((step) => step - 1);
  };

  const renderActions = () => (
    <>
      {actions.map((action) => {
        if (!action.isAvailable) {
          return null;
        }
        return (
          <Button
            key={action.label}
            onClick={action.onClick}
            classes={addStylesToActionButton}
          >
            {action.label}
          </Button>
        );
      })}
    </>
  );

  return {
    actions: renderActions(),
    renderedStep: !!RenderedStep ? <RenderedStep scenario={scenario} /> : null,
  };
};
export default useIntroductionPlayer;

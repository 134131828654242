import React, { FunctionComponent } from 'react';
import logo from './../../assets/images/logo-min.png';
import { AppRoutes } from '../../config/routes';
import { Link, useNavigate } from 'react-router-dom';
import AccountDropdown from '../../features/common/components/AccountDropdown/AccountDropdown';
import { useUser } from '../../features/common/hooks/useUser';

export type TopbarProps = {
  pageTitle: string;
} & JSX.IntrinsicElements['div'];

const CtaButton: React.FC = () => {
  useNavigate();
  const currentPath = window.location.pathname;
  const playDrillsPath = AppRoutes.ScenarioLevels.replace(
    ':id',
    'online-boutique',
  );

  return (
    <>
      {currentPath === playDrillsPath ? (
        <a
          className="Topbar__cta-link Topbar__cta-link--hover Topbar__cta-link--feedback"
          href="https://uptimelabs.canny.io/uptimelabs-feedback"
          target="_blank"
          rel="noopener noreferrer"
        >
          SHARE FEEDBACK
        </a>
      ) : (
        <Link
          to={playDrillsPath}
          className="Topbar__cta-link Topbar__cta-link--hover Topbar__cta-link--playDrill"
        >
          PLAY DRILLS
        </Link>
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Topbar: FunctionComponent<TopbarProps> = ({ pageTitle, ...rest }) => {
  const user = useUser();
  return (
    <div className="Topbar" {...rest}>
      <Link to={AppRoutes.Index}>
        <img className="Topbar__logo" src={logo} alt="UptimeLabs" />
      </Link>
      <ul className="Topbar__nav">
        <li>
          <Link className="Topbar__link" to={AppRoutes.Index}>
            ONLINE BOUTIQUE
          </Link>
        </li>
        <li>
          <Link className="Topbar__link" to={AppRoutes.Reports}>
            REPORTS
          </Link>
        </li>
        <li>
          <Link className="Topbar__link" to={AppRoutes.LearningCenter}>
            LEARNING
          </Link>
        </li>
        <li>
          <CtaButton />
        </li>
      </ul>
      {user?.email && <AccountDropdown />}
    </div>
  );
};

export default Topbar;
export { CtaButton };

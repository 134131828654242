import React, { FunctionComponent, useState, useEffect } from 'react';
import PostList from './PostList';
import { Post } from '../content/types';
import { DependencyContainer } from '../../DependencyContainer';

const LearningCenter: FunctionComponent = () => {
  const { postsService } = DependencyContainer.getInstance();
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const fetchedPosts = await postsService.getPosts();
        if (typeof fetchedPosts !== 'undefined') {
          setPosts(fetchedPosts);
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, [postsService]);

  return (
    <div className="learning-center">
      <h1>Learning Centre</h1>
      <p className="learning-center-intro-container">
        {' '}
        In betweeen drills? check out the posts below to boost your learning
      </p>
      <PostList posts={posts} />
    </div>
  );
};

export default LearningCenter;

import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

export type SeparatorStyles = {
  root: string;
  title: string;
};

export type SeparatorProps = {
  title: string;
} & JSX.IntrinsicElements['div'];

const Separator: AdjustableComponent<SeparatorProps, SeparatorStyles> = ({
  title,
  ...props
}) => {
  const classes = useClasses(
    {
      root: 'Separator',
      title: 'Separator__title',
    },
    props.classes,
  );

  return (
    <div {...props} className={classes.root}>
      <span className={classes.title}>{title}</span>
    </div>
  );
};

export default Separator;

import { FunctionComponent } from 'react';
import Modal, { ModalStyles } from '../../../components/Modal/Modal';
import ModalHeader from '../../../components/Modal/ModalHeader';
import ModalBody from '../../../components/Modal/ModalBody';
import { Form, Formik, Field } from 'formik';
import { reportsSelectedUserEmailSelector } from '../../../state';
import { useRecoilValue } from 'recoil';
import { Maybe } from '../../common/types';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import ModalFooter from '../../../components/Modal/ModalFooter';
import * as yup from 'yup';
import { usePersistentState } from '../../../persistent-state/persistent-state';

export type ChoosePlayerEmailModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

type PlayerForm = {
  email: Maybe<string>;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Should be valid e-mail address')
    .required('E-mail is required'),
});

const ChoosePlayerEmailModal: FunctionComponent<
  ChoosePlayerEmailModalProps
> = ({ isOpen, onRequestClose }) => {
  const [, setReportsSelectedUserEmail] = usePersistentState(
    'reportsSelectedUserEmail',
  );
  const reportsSelectedUserEmail = useRecoilValue(
    reportsSelectedUserEmailSelector,
  );

  const onSubmit = (values: PlayerForm) => {
    setReportsSelectedUserEmail(values.email);
    onRequestClose();
  };

  const getFormInitialValues = (): PlayerForm => {
    return {
      email: reportsSelectedUserEmail,
    };
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        classes={(current: ModalStyles) => ({
          ...current,
          root: `${current.root} ChoosePlayerEmailModal`,
        })}
      >
        <ModalHeader title="Set player email" />
        <ModalBody>
          <Formik
            initialValues={getFormInitialValues()}
            enableReinitialize
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {({ errors }) => (
              <Form>
                <Field
                  name="email"
                  component={Input}
                  type="text"
                  placeholder="User email"
                />
                {errors && errors.email && (
                  <span className="error">{errors.email}</span>
                )}
                <ModalFooter>
                  <Button
                    size="small"
                    variant="link"
                    type="button"
                    onClick={() => onRequestClose()}
                  >
                    Cancel
                  </Button>
                  <Button size="small" type="submit">
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChoosePlayerEmailModal;
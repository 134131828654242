import { DependencyContainer } from '../../../DependencyContainer';
import { PlayerRoles } from '../types';
import { MqttStaticTopics } from '../mqtt-topics';

export default class PlayerService {
  constructor(private readonly factory: DependencyContainer) {}

  savePlayerRole(email: string, role: PlayerRoles) {
    this.factory.mqttService.publish({
      topic: MqttStaticTopics.CONFIGURE_PLAYER,
      payload: {
        player: email,
        persona: role,
      },
    });
  }
}

import React from 'react';
import Button from '../Button/Button';
import './UpcomingLevel.scss';
import { WordpressContent, WordpressLevel } from '../../features/content/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';


interface ComingSoonCardProps {
    level: WordpressContent<WordpressLevel>;
}

const ComingSoonCard: React.FC<ComingSoonCardProps> = ({ level }) => {
    return (
        <div className='coming-soon-container' key={level.slug}>
            <Button className='coming-soon-button'>
                <div className="pending-content-card">
                    <span className="pending-level-title">{level.title.rendered}</span>
                    <span className="pending-content">Coming Soon</span>
                    <span className="locked-level">
                        <FontAwesomeIcon icon={faLock}/>
                    </span>
                </div>
            </Button>
        </div>
    );
};

export default ComingSoonCard;


import React from 'react';
import cx from 'classnames';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';
import { omit } from '../../features/common/helpers/objects.helper';

export type SpinnerLoaderStyles = {
  root: string;
};

export enum SpinnerLoaderSizes {
  Small = 'small',
  Normal = 'normal',
}
export type SpinnerLoaderProps = JSX.IntrinsicElements['div'] & {
  visible?: boolean;
  size?: SpinnerLoaderSizes;
  message?: string
};

const SpinnerLoader: AdjustableComponent<
  SpinnerLoaderProps,
  SpinnerLoaderStyles
> = (props) => {
  const propsToPass = omit(
    props,
    'className',
    'children',
    'classes',
    'visible',
    'size',
    'message',
  );
  const size = props.size || SpinnerLoaderSizes.Normal;
  const classes = useClasses(
    {
      root: cx('Spinner', {
        [`Spinner--${size}`]: size,
        'Spinner--visible': Boolean(props.visible),
      }),
    },
    props.classes,
  );

  return <div> <div {...propsToPass} className={classes.root} />  </div>;
};

export default SpinnerLoader;

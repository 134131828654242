import { Arraize } from '../common/types';
import { UrlsKeys } from '../../config/urls';
import { FunctionComponent, ReactElement } from 'react';

export enum ScenarioStatuses {
  Unlocked = 'unlocked',
  Locked = 'locked',
}

export enum ScenarioLevels {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
  Level4 = 4,
  Level5 = 5,
  Level6 = 6,
  Level7 = 7,
  Level8 = 8,
  Level9 = 9,
  Level10 = 10,
  Level11 = 11,
  Level12 = 12,
  Level13 = 13,
  Level14 = 14,
  Level15 = 15,
  Level16 = 16,
  Level17 = 17,
  Level18 = 18,
  Level19 = 19,
  Level20 = 20,
  Level21 = 21,
  Level22 = 22,
  Level23 = 23,
  Level24 = 24,
  DevelopmentDrill = 100,
}
export enum ScenarioDifficulties {
  Low = 1,
  Intermediate = 2,
  Hard = 3,
  Extreme = 4,
  SuperExtreme = 5,
}

export type ScenarioMedia = {
  imageUrl: string;
  videoUrl: string;
};

export type ScenarioLevelDescription = {
  sectionTitle: string;
  listedElements: string[];
};

export type ScenarioLevel = {
  level: ScenarioLevels;
  name: string;
  timeInSeconds: number;
  isCompleted: boolean;
  isAvailable: boolean;
  description: Arraize<Arraize<ScenarioLevelDescription | ReactElement>>;
  audio?: string;
  environmentUrlGettersKeys?: EnvironmentUrlGetter[];
  environmentUrlGettersKeysSRE?: EnvironmentUrlGetter[];
  environmentUrlGettersKeysIM?: EnvironmentUrlGetter[];
};

export type EnvironmentUrlGetter = {
  label: string;
  key: UrlsKeys;
  highlighted?: boolean;
};

export type ScenarioContent = Record<string, any>;

export type Scenario = {
  id: string;
  title: string;
  difficulty: ScenarioDifficulties;
  levels: ScenarioLevel[];
  status: ScenarioStatuses;
  outageCostPerMinute?: number;
  media?: Partial<ScenarioMedia>;
  allEnvironmentUrlGetterKeys?: EnvironmentUrlGetter[];
  content?: ScenarioContent;
  wordpressContentId?: number;
};

export type ScenarioIntroduction = {
  scenario: Scenario;
  onFinishIntroduction: () => void;
  step?: number;
  hideControls?: boolean;
};

export type ScenarioIntroductionStepDefinition = {
  step: number;
  component: FunctionComponent<any>;
};

import React, { FunctionComponent, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import persistentState from './persistent-state';

const PersistentStateRoot: FunctionComponent = (props) => {
  const setAudiosPlayed = useSetRecoilState(
    persistentState.getAtom('audiosPlayed'),
  );
  const setTourRan = useSetRecoilState(persistentState.getAtom('tourRan'));
  const setSelectedPlayerRole = useSetRecoilState(
    persistentState.getAtom('selectedPlayerRole'),
  );
  const setReportsSelectedUserEmail = useSetRecoilState(
    persistentState.getAtom('reportsSelectedUserEmail'),
  );

  useEffect(() => {
    const unsubscribe = persistentState.subscribe((key: any, value: any) => {
      switch (key) {
        case 'audiosPlayed':
          setAudiosPlayed(value);
          break;
        case 'tourRan':
          setTourRan(value);
          break;
        case 'selectedPlayerRole':
          setSelectedPlayerRole(value);
          break;
        case 'reportsSelectedUserEmail':
          setReportsSelectedUserEmail(value);
          break;
      }
    });
    return unsubscribe;
  }, []);

  return <>{props.children}</>;
};

export default PersistentStateRoot;

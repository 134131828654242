import {
  Scenario,
  ScenarioDifficulties,
  ScenarioLevels,
  ScenarioStatuses,
} from '../types';
import { commonLevelDescription } from './common-level-description';

const billyHillMock: Scenario = {
  id: 'billy-hill',
  title: 'Billy Hill',
  difficulty: ScenarioDifficulties.Hard,
  levels: [
    {
      level: ScenarioLevels.Level1,
      name: 'Beginner',
      timeInSeconds: 5 * 60,
      isCompleted: false,
      isAvailable: false,
      description: commonLevelDescription,
    },
    {
      level: ScenarioLevels.Level2,
      name: 'Intermediate',
      timeInSeconds: 15 * 60,
      isCompleted: false,
      isAvailable: false,
      description: commonLevelDescription,
    },
    {
      level: ScenarioLevels.Level3,
      name: 'Advanced',
      timeInSeconds: 20 * 60,
      isCompleted: false,
      isAvailable: false,
      description: commonLevelDescription,
    },
  ],
  status: ScenarioStatuses.Locked,
};

export default billyHillMock;

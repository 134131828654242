import Keycloak from 'keycloak-js';
import {
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
} from '../../config/keycloak';

const config: Keycloak.KeycloakConfig = {
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};

//@ts-ignore
const authClient: Keycloak.KeycloakInstance = new Keycloak(
  config,
) as Keycloak.KeycloakInstance;

export default authClient;

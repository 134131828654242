import ScenariosService from './features/scenarios/services/ScenariosService';
import ScenariosClient from './features/scenarios/services/ScenariosClient';
import MqttService from './features/common/services/MqttService';
import ContentClient from './features/content/services/ContentClient';
import ContentService from './features/content/services/ContentService';
import ReportsService from './features/reports/services/ReportsService';
import ReportsClient from './features/reports/services/ReportsClient';
import PlayerService from './features/common/services/PlayerService';
import AwardService from './features/awards/services/AwardService';
import AwardClient from './features/awards/services/AwardClient';
import PostsClient from './features/learning/services/PostsClient';
import PostsService from './features/learning/services/PostsService';
import LobbyService from './features/scenarios/components/Lobby/services/LobbyService';
import LobbyClient from './features/scenarios/components/Lobby/services/LobbyClient';
import SessionService from './features/scenarios/components/Session/services/SessionService';
import SessionClient from './features/scenarios/components/Session/services/SessionClient';

let instance: DependencyContainer;

export class DependencyContainer {
  // services
  scenariosService = new ScenariosService(this);
  mqttService = new MqttService(this);
  contentService = new ContentService(this);
  reportsService = new ReportsService(this);
  playerService = new PlayerService(this);
  awardService = new AwardService(this);
  postsService = new PostsService(this);
  lobbyService = new LobbyService(this);
  sessionService = new SessionService(this);

  // clients
  scenariosClient = new ScenariosClient(this);
  contentClient = new ContentClient(this);
  reportsClient = new ReportsClient(this);
  awardClient = new AwardClient(this);
  postsClient = new PostsClient(this);
  lobbyClient = new LobbyClient(this);
  sessionClient = new SessionClient(this);

  static getInstance() {
    if (!instance) {
      instance = new DependencyContainer();
    }
    return instance;
  }
}

import React from 'react';
import Button from '../Button/Button';
import { WordpressContent, WordpressLevel } from '../../features/content/types';
import './List.scss';
import { useUser } from '../../features/common/hooks/useUser';
import ComingSoonCard from '../UpcomingLevelCard/UpcomingLevel';

interface ListProps {
  levels: Array<WordpressContent<WordpressLevel>>;
  startLevel: (levelSlug: string) => void;
}

const List: React.FC<ListProps> = ({ levels, startLevel }) => {
  const user = useUser();

  return (
    <div>
      <hr className="carousel-divide" />
      <div className="list-title">ALL DRILLS</div>
      <div className="list-container">
        {levels.map((level: WordpressContent<WordpressLevel>) => {
          if (level.acf.is_available || user.isAdmin) {
            return (
              <div className="button-text" key={level.slug}>
                <Button
                  className="button-text"
                  onClick={() => startLevel(level.slug)}
                >
                  <div className="content-card">
                    <div className="card-text">
                      <span className="level-title">
                        {level.title.rendered}
                      </span>
                      <span
                        className={`level-difficulty`}
                        data-difficulty={level.acf.difficulty}
                      >
                        {level.acf.difficulty}
                      </span>
                    </div>
                  </div>
                </Button>
              </div>
            );
          } else {
            return <ComingSoonCard key={level.slug} level={level} />;
          }
        })}
      </div>
    </div>
  );
};

export default List;

import React from 'react';
import {
  AdjustableComponentType,
  useClasses,
} from '../../features/common/hooks/useClasses';
import Table from './Table';
import TableHead from './TableHead';
import TableHeadColumn from './TableHeadColumn';
import TableRow from './TableRow';
import TableBody from './TableBody';
import TableColumn from './TableColumn';
import { useMedia } from '../../features/common/hooks/useMedia';
import cx from 'classnames';
import Joyride, { Step } from 'react-joyride';
import { usePersistentState } from '../../persistent-state/persistent-state';
import { joyrideStyles } from '../../config/config';
import { useFeatureFlag } from '../../features/common/hooks/useFeatureFlag';
import { Features } from '../../config/features';

export type TableCell = JSX.Element | string | number | JSX.Element[];

export type HeadingRow<D> = {
  name?: string;
  fieldName?: string;
  render?: (row: D) => TableCell;
};

export type DataRow<D> = D;

export type TableResponsive<D> = {
  headingRows: Array<HeadingRow<D>>;
  dataRows: Array<DataRow<D>>;
};

export type TableStyles = {
  root: string;
};

export type ResponsiveTableProps<D> = TableResponsive<D> &
  JSX.IntrinsicElements['table'];

export default function ResponsiveTable<T>(
  props: AdjustableComponentType<TableResponsive<T>, TableStyles>,
): JSX.Element {
  const { sizes } = useMedia();
  const [tourRan] = usePersistentState('tourRan');
  const isOnBoardingAvailable = useFeatureFlag(Features.OnBoarding);
  const styles = useClasses(
    {
      root: cx({
        'Table--small-screen': sizes.small,
        'Table--medium-screen': sizes.medium,
        'Table--large-screen': sizes.large,
      }),
    },
    props.classes,
  );

  const renderCell = (row: T, heading?: HeadingRow<T>) => {
    if (heading === undefined) {
      return '';
    }
    if (heading.render) {
      return heading.render(row);
    }
    // @ts-expect-error heading is not undefined??
    return row[heading.fieldName];
  };

  const steps: Step[] = [
    {
      target: '.Button--secondary',
      content: 'Click here to begin your journey with our scenario.',
      placement: 'top-start',
    },
  ];

  if (sizes.small) {
    return (
      <>
        {isOnBoardingAvailable() && (
          <Joyride
            steps={steps}
            stepIndex={0}
            continuous
            run={!tourRan}
            scrollToFirstStep
            styles={joyrideStyles}
          />
        )}
        {props.dataRows.map((data) => (
          <Table
            classes={(current: TableStyles) => ({
              ...current,
              root: `${current.root} ${styles.root}`,
            })}
          >
            <TableBody>
              {props.headingRows.map((heading) => (
                <TableRow>
                  <TableColumn>{heading.name}</TableColumn>
                  <TableColumn>{renderCell(data, heading)}</TableColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ))}
      </>
    );
  }

  return (
    <>
      {isOnBoardingAvailable() && (
        <Joyride
          steps={steps}
          stepIndex={0}
          continuous
          run={!tourRan}
          scrollToFirstStep
          styles={joyrideStyles}
        />
      )}
      <Table
        classes={(current: TableStyles) => ({
          ...current,
          root: `${current.root} ${styles.root}`,
        })}
      >
        <TableHead>
          {props.headingRows.map((headingRow) => (
            <TableHeadColumn key={headingRow.name}>
              {headingRow.name}
            </TableHeadColumn>
          ))}
        </TableHead>
        <TableBody>
          {props.dataRows.map((data: DataRow<T>, index: number) => (
            <TableRow key={index}>
              {props.headingRows.map((heading: HeadingRow<T>) => (
                <TableColumn key={`${heading.fieldName}-${index}`}>
                  {renderCell(data, heading)}
                </TableColumn>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { CardStyles } from '../../../components/Card/Card';
import Button from '../../../components/Button/Button';
import { useFetchData } from '../../common/hooks/useFetchData';
import {
  Feedback,
  LevelStates,
  Report,
  ReportPlayerAssessment,
  ReportTimeLineEvent,
} from '../../reports/types';
import { DependencyContainer } from '../../../DependencyContainer';
import { useBearerToken } from '../../common/hooks/useBearerToken';
import { useParams } from 'react-router-dom';
import PendingContent from '../../../components/PendingContent/PendingContent';
import SpinnerLoader from '../../../components/SpinnerLoader/SpinerLoader';
import ReportSummaryCard from '../../reports/components/ReportSummaryCard';
import ReportTimeLineCard from '../../reports/components/ReportTimeLineCard';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import { AppRoutes } from '../../../config/routes';
import { AxiosError } from 'axios';
import ReportFeedbackCard from '../../reports/components/ReportFeedbackCard';
import { useUser } from '../../common/hooks/useUser';
import { reportsSelectedUserEmailSelector } from '../../../state';
import { useRecoilValue } from 'recoil';
import { Maybe } from '../../common/types';

const addStylesToCard = (current: CardStyles) => ({
  ...current,
  root: `${current.root} ReportContainer__card`,
  content: `${current.content} ReportContainer__card-content`,
});

const { reportsService } = DependencyContainer.getInstance();

const ReportContainer = () => {
  const { id } = useParams();
  const user = useUser();
  const reportsSelectedUserEmail = useRecoilValue(
    reportsSelectedUserEmailSelector,
  );
  const token = useBearerToken();
  const redirectToRoute = useRedirectToRoute();

  const getEmail = (): Maybe<string> => {
    const email =
      user.isAdmin && reportsSelectedUserEmail
        ? reportsSelectedUserEmail
        : undefined;
    return email;
  };

  const getThrottleConditions = (): boolean => {
    return !token || !id || !reportsSelectedUserEmail || !user;
  };

  const [loading, report, error] = useFetchData<Report, AxiosError>(
    () => {
      return reportsService.getReport(parseInt(id!), token!, getEmail());
    },
    undefined,
    [token, id],
    () => getThrottleConditions(),
  );

  const [loadingTimeLineEvents, timeLineEvents] = useFetchData<
    ReportTimeLineEvent[],
    Error
  >(
    () => {
      return reportsService.getTimeLineEvents(
        parseInt(id!),
        token!,
        getEmail(),
      );
    },
    undefined,
    [token, id],
    () => getThrottleConditions(),
  );

  const [loadingAssessment, assessment, assessmentError] = useFetchData<
    ReportPlayerAssessment,
    Error
  >(
    () => {
      return reportsService.getPlayerAssessment(
        parseInt(id!),
        token!,
        getEmail(),
      );
    },
    undefined,
    [token, id],
    () => getThrottleConditions(),
  );

  const [, feedback] = useFetchData<Feedback[], Error>(
    () => {
      return reportsService.getPlayerFeedback(
        parseInt(id!),
        token!,
        getEmail(),
      );
    },
    undefined,

    [token, id],
    () => getThrottleConditions(),
  );

  const goBack = () => {
    redirectToRoute(AppRoutes.Reports);
  };

  const renderError = () => {
    if (!error) {
      return null;
    }
    const code = error?.response?.status;
    switch (code) {
      case 404:
        return (
          <>
            <h4>Report not found</h4>
            <Button size="small" onClick={() => goBack()}>
              go back
            </Button>
          </>
        );
      default:
        return 'Something went wrong';
    }
  };
  const isIncomplete =
    Boolean(timeLineEvents?.find((e) => e.state === LevelStates.OutOfTime)) ||
    !Boolean(timeLineEvents?.find((e) => e.state === LevelStates.Completed));

  return (
    <div className="ReportContainer">
      {report && (
        <div className="ReportContainer__action-buttons">
          <Button size="small" onClick={() => goBack()}>
            back
          </Button>
        </div>
      )}

      <PendingContent
        loading={loading || loadingTimeLineEvents}
        isError={Boolean(error)}
        hideContent
        loader={SpinnerLoader}
        message={renderError()}
      >
        {report && timeLineEvents && (
          <>
            <ReportSummaryCard
              report={report}
              isIncomplete={isIncomplete}
              stylesProvider={addStylesToCard}
            />
            <ReportTimeLineCard
              stylesProvider={addStylesToCard}
              timeLineEvents={timeLineEvents}
            />
            <ReportFeedbackCard
              loading={loadingAssessment}
              error={assessmentError}
              assessment={assessment}
              playerEmail={report.player_email}
              feedback={feedback}
              stylesProvider={addStylesToCard}
            />
          </>
        )}
      </PendingContent>
    </div>
  );
};

export default ReportContainer;

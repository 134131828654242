import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';
import { omit } from '../../features/common/helpers/objects.helper';

export type ProgressBarStyles = {
  root: string;
  bar: string;
  label: string;
};

export type ProgressBarProps = {
  progress: number;
  renderLabel?: (progress: number) => string;
} & JSX.IntrinsicElements['div'];

const ProgressBar: AdjustableComponent<ProgressBarProps, ProgressBarStyles> = ({
  title,
  ...props
}) => {
  const classes = useClasses(
    {
      root: 'ProgressBar',
      bar: 'ProgressBar__bar',
      label: 'ProgressBar__label',
    },
    props.classes,
  );
  const width = `${props.progress}%`;
  const propsToPass = omit(props, 'renderLabel', 'progress');

  return (
    <div {...propsToPass} className={classes.root}>
      {props.renderLabel && (
        <div className={classes.label}>{props.renderLabel(props.progress)}</div>
      )}
      <div className={classes.bar} style={{ width }} />
    </div>
  );
};

export default ProgressBar;

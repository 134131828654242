import React, { ReactNode, useCallback } from 'react';
import cx from 'classnames';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

type Option = {
  id: string;
  label: string | ReactNode;
  content: ReactNode | string;
  visible?: boolean;
};

export type TabsProps = {
  options: Array<Option>;
  active: number;
  onSelectTab: (option: Option, index: number) => void;
};

export type TabsStyles = {
  root: string;
  header: string;
  label: string;
  content: string;
};

const Tabs: AdjustableComponent<TabsProps, TabsStyles> = ({
  options,
  onSelectTab,
  active,
  classes,
}) => {
  const handleLabelClick = useCallback(
    (index: number) => () => {
      onSelectTab(options[index], index);
    },
    [onSelectTab, options],
  );

  const styles = useClasses(
    {
      root: 'Tabs',
      header: 'Tabs__header',
      label: 'Tabs__label',
      content: 'Tabs__content',
    },
    classes,
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {options.map((option, index) => (
          <button
            key={`${option.id}`}
            className={cx([
              { [styles.label]: true },
              { [`${styles.label}--active`]: index === active },
              { [option.id]: true },
              {
                hidden: option.visible === undefined ? false : !option.visible,
              },
            ])}
            onClick={handleLabelClick(index)}
          >
            {option.label}
          </button>
        ))}
      </div>
      <div className={styles.content}>
        {options.map((option, index) => (
          <div
            className={cx('Tabs__content-inner', {
              'fade-in': active === index,
              'fade-out': active !== index,
            })}
            key={`${option.id}`}
          >
            {option.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;

import { useKeycloak } from '@react-keycloak/web';
import { Maybe, PlayerRoles } from '../types';

export type User = {
  id: string;
  email: string;
  roles: string[];
  isAdmin: boolean;
  isAIXAdmin: boolean;
  tenant: string;
  name?: string;
  surname?: string;
  persona: Maybe<PlayerRoles>;
  isNewInfra: boolean;
  sessionInfra: string;
  // group?: string[];
};

export const useUser = (): User => {
  const { keycloak } = useKeycloak();
  const id = keycloak.tokenParsed?.sub || '';
  const email = keycloak.tokenParsed?.email;
  const persona = keycloak.tokenParsed?.persona || undefined;
  const roles =
    keycloak.tokenParsed?.realm_access?.roles.filter((role: string) =>
      role.match(/^tenant-(.*)/),
    ) || [];
  const isAdmin = Boolean(
    (keycloak.tokenParsed?.realm_access?.roles || []).filter((r) =>
      ['admins', 'app-admin'].includes(r),
    ).length,
  );
  const isAIXAdmin = Boolean(
    (keycloak.tokenParsed?.realm_access?.roles || []).filter((r) =>
      ['aix-admin', 'app-admin'].includes(r),
    ).length,
  );

  const isNewInfra = Boolean(
    (keycloak.tokenParsed?.realm_access?.roles || []).filter((r) =>
      ['NewInfra'].includes(r),
    ).length,
  );
  const sessionInfra = 'uptimelabs';
  // const group = keycloak.tokenParsed?.realm_access?.roles || undefined;
  return {
    id,
    email,
    roles,
    isAdmin,
    isAIXAdmin,
    persona,
    tenant: roles[0]?.replace('tenant-', ''),
    isNewInfra,
    sessionInfra,
  };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { DependencyContainer } from '../../../DependencyContainer';
import mqtt, { IClientSubscribeOptions, IPublishPacket, MqttClient } from 'mqtt';
import { MqttPublishParams, MqttTopics} from '../mqtt-topics';
import { ISubscriptionGrant } from 'mqtt/types/lib/client';
import { MQTT_HOST, MQTT_PASSWORD, MQTT_USERNAME, MQTT_PATH } from '../../../config/mqtt';
import { Maybe } from '../types';
import { getMQTTPort } from '../../../config/environment';

export interface MqttServiceProtocol {
  publish(params: MqttPublishParams, onSuccess?: () => void): void;
}

export default class MqttService implements MqttServiceProtocol {
  private client: Maybe<MqttClient>;

  constructor(private readonly factory: DependencyContainer) {
    this.connect();
  }

  connect() {
    if (this.client) {

      return;
    }
    this.client = mqtt.connect({
      username: MQTT_USERNAME,
      password: MQTT_PASSWORD,
      port: getMQTTPort(),
      protocol: 'wss',
      hostname: MQTT_HOST,
      path: MQTT_PATH,
    });
    this.client.on('error', (error: Error) => {
      console.error('Mqtt Error', error);
    });
  }

  subscribe<M>(topicName: string, onUpdate: (message: M) => void) {
    if (!this.client) {
      console.log('Cannot subscribe, MQTT client is not connected');
      return;
    }

    this.client.subscribe(topicName, (error: Error | null,  granted?: ISubscriptionGrant[]) => {
      if (error) {
        console.error('Subscription error:', error);
      }
    });

    this.client.on('message', (topic: string, message: Buffer, packet: IPublishPacket) => {

      if (topic === topicName) {
          const parsedMessage = JSON.parse(message.toString());
          onUpdate(parsedMessage);

      }
    });
  }

  unsubscribe(topicName: string) {
    if (!this.client) {
      return;
    }
    this.client.unsubscribe(
      topicName,
      () => {
        // console.log('err', error);
        // console.log('granted', granted);
      },
    );
  }

  publish(params: MqttPublishParams | any, onSuccess?: () => void) {
    if (!this.client) {
      return;
    }
    const { topic, payload } = params;
    this.client.publish(topic, JSON.stringify(payload), () => {
      onSuccess?.();
    });
  }

  end() {
    if (!this.client) {
      return;
    }
    this.client.end();
  }
}

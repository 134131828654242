import React from 'react';
import IncidentManager from './incident-manager';
import { PlayerRoles } from '../../../../../features/common/types';
import { useRecoilValue } from 'recoil';
import { playerRoleSelector } from '../../../../../state';
import SiteReliabilityEngineer from './site-reliability-engineer';

const OnlineBoutiqueStep5: React.FC = () => {
  const playerRole = useRecoilValue(playerRoleSelector);

  return (
    <div className="__role-intro">
      {playerRole === PlayerRoles.IncidentManager ? (
        <IncidentManager />
      ) : (
        <SiteReliabilityEngineer />
      )}
    </div>
  );
};

export default OnlineBoutiqueStep5;

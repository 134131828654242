import React, { FunctionComponent, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
} from 'react-flow-renderer';

import { Connection } from 'react-flow-renderer/dist/esm/types/general';
import useOnlineBoutiqueStaffStructure from '../../../hooks/useOnlineBoutiqueStaffStructure';
import StaffDetailsModal from '../../StaffDetailsModal/StaffDetailsModal';
import { IntroductionPlayerStep } from '../../IntroductionPlayer/IntroductionPlayer';
import AudioPlayer, {
  AudioPlayerStyles,
} from '../../../../../components/AudioPlayer/AudioPlayer';
import Joyride, { Step } from 'react-joyride';
import { usePersistentState } from '../../../../../persistent-state/persistent-state';
import { joyrideStyles } from '../../../../../config/config';
import { Features } from '../../../../../config/features';
import { useFeatureFlag } from '../../../../common/hooks/useFeatureFlag';

const OnlineBoutiqueIntroductionStep3: FunctionComponent<
  IntroductionPlayerStep
> = () => {
  const { initialEdges, initialNodes, selectedPerson, setSelectedPerson } =
    useOnlineBoutiqueStaffStructure();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const isOnBoardingAvailable = useFeatureFlag(Features.OnBoarding);
  const onConnect = (params: Connection) =>
    setEdges((eds) => addEdge(params, eds));

  const steps: Step[] = [
    {
      target: '.OnlineBoutiqueIntroduction__flow',
      content:
        'Discover the Online Boutique (OB) organizational chart provided below. Click on each team member to delve into more details about their roles and contributions.',
    },
  ];
  const [tourRan] = usePersistentState('tourRan');

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [initialNodes, initialEdges, setNodes, setEdges]);

  return (
    <>
      <AudioPlayer
        source="/audio/org_chart_intro.mp3"
        classes={(current: AudioPlayerStyles) => ({
          ...current,
          root: `${current.root} OnlineBoutiqueIntroduction__audio-player`,
        })}
      />
      {isOnBoardingAvailable() && (
        <Joyride
          steps={steps}
          stepIndex={0}
          run={!tourRan}
          scrollToFirstStep
          showSkipButton
          styles={joyrideStyles}
        />
      )}
      <div className="OnlineBoutiqueIntroduction__flow">
        <h2> Organisation Chart </h2>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodesDraggable={false}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          zoomOnScroll={false}
          fitView
          snapToGrid
          draggable={false}
          attributionPosition="top-right"
        />
      </div>
      {selectedPerson && (
        <StaffDetailsModal
          isOpen={Boolean(selectedPerson)}
          person={selectedPerson}
          onRequestClose={() => setSelectedPerson(undefined)}
        />
      )}
    </>
  );
};

export default OnlineBoutiqueIntroductionStep3;

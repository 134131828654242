import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

export type ModalFooterStyles = {
  root: string;
};

const ModalFooter: AdjustableComponent<{}, ModalFooterStyles> = (props) => {
  const styles = useClasses<ModalFooterStyles>(
    {
      root: 'Modal__footer',
    },
    props.classes,
  );

  return <div className={styles.root}>{props.children}</div>;
};

export default ModalFooter;

import { useUser } from './useUser';
import { useCallback } from 'react';
import { featureFlagsConfiguration, Features } from '../../../config/features';

export const useFeatureFlag = (feature: Features) => {
  const user = useUser();
  return useCallback(() => {
    try {
      return (
        user.isAdmin ||
        Boolean(featureFlagsConfiguration[feature].includes(user.email))
      );
    } catch (error) {
      return false;
    }
  }, [user, feature]);
};

import React from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../features/common/hooks/useClasses';

export type TableHeadColumnStyles = {
  root: string;
};

export type TableHeadColumnProps = JSX.IntrinsicElements['th'];

const TableHeadColumn: AdjustableComponent<
  TableHeadColumnProps,
  TableHeadColumnStyles
> = ({ classes, children }) => {
  const styles = useClasses(
    {
      root: 'Table__head-column',
    },
    classes,
  );
  return <th className={styles.root}>{children}</th>;
};

export default TableHeadColumn;

import { FunctionComponent } from 'react';
import { WordpressContent, WordpressLevel } from '../../../content/types';
import { Scenario } from '../../types';
import { DependencyContainer } from '../../../../DependencyContainer';
import Button from '../../../../components/Button/Button';
import './lobby.scss';
import { useBearerToken } from '../../../common/hooks/useBearerToken';
import { useEffect, useState, useMemo } from 'react';
import { Lobby, LobbyPlayer } from './types';
import CheckIcon from '../../../../assets/icons/icons_check_16.svg';
import CrossIcon from '../../../../assets/icons/icons_close_16.svg';
import { User } from '../../../common/hooks/useUser';
import CopyIcon from '../../../../assets/icons/icons_report_12.svg';
import  ClockIcon  from '../../../../assets/icons/icons_clock_24.svg';
import launchIcon from '../../../../assets/icons/icons_launch_24.svg';
import GoalGoldIcon from '../../../../assets/icons/icons_goal_gold_24.svg';
import GoalSilverIcon from '../../../../assets/icons/icons_goal_silver_24.svg';
import GoalBronzeIcon from '../../../../assets/icons/icons_goal_bronze_24.svg';
import { AppRoutes } from '../../../../config/routes';
import { useRedirectToRoute } from '../../../common/hooks/useRedirectToRoute';
import { getSlackChannelForTenant } from '../../../../config/slack';
import { useSetRecoilState } from 'recoil';
import { GamePlay, gamePlayAtom, GameStatus } from '../../../../state';

const { lobbyService, sessionService, scenariosService } = DependencyContainer.getInstance();
interface LobbyCardProps {
  scenario: Scenario;
  level: WordpressContent<WordpressLevel>;
  user: User;
  keycloak: unknown;
  readyToPlay: boolean;
  isJoiningExistingLobby: boolean;
  joiningLobby: Lobby | null;
}

const LobbyCard: FunctionComponent<LobbyCardProps> = ({
  scenario,
  level,
  user,
  readyToPlay,
  isJoiningExistingLobby,
  joiningLobby,
}) => {
  const [lobby, setLobby] = useState<Lobby | null>(joiningLobby);
  const [lobbyLink, setLobbyLink] = useState<string | null>();
  const token = useBearerToken();
  const levelId = level?.acf.level_id;
  const [isLobbyActive, setIsLobbyActive] = useState(
    lobby?.status === 'active',
  );
  const setGamePlay = useSetRecoilState<GamePlay>(gamePlayAtom);
  const goToRoute = useRedirectToRoute();
  const [hasJoined, setHasJoined] = useState(false);
  const [isPlayerInLobby, setIsPlayerInLobby] = useState(
    lobby?.players?.some((player) => player.player === user.email && player.status === 'active'),
  );
  const [playersInLobby, setPlayersInLobby] = useState<LobbyPlayer[]>([]);


  const startSession = async () => {
    try {
      const session = await sessionService.createSession(token!, {
        lobbyId: lobby!.id,
      });
      sessionService.setSession(user.id, {
        scenario: session.scenario,
        level: session.level,
        status: "ready",
        game_remaining_seconds: 0,
      });
      if (session) {
        readyToPlay = true;
      }
    }
     catch (error) {
      console.error('Error creating session:', error);
    }
  };

  const createLobby = async () => {
    if (token) {
      const newlobby = await lobbyService.createLobby(token, {
        scenario: scenario.id,
        level: levelId,
      });
      setLobby(newlobby);
      setIsLobbyActive(true);
      setIsPlayerInLobby(true);
      lobbyService.setPlayersinLobby(newlobby.id, newlobby.players);
      if (newlobby) {
        const link = await lobbyService.generateLobbyJoinLink(token, newlobby);
        setLobbyLink(link);
      }
    }
  };

  const closeLobby = async () => {
    if (token && lobby) {
      await lobbyService.closeLobby(token, {
        lobbyId: lobby.id,
      });
      setIsLobbyActive(false);
      setLobbyLink(null);
      setLobby(null);
    }
  };

  const copyToClipboard = () => {
    if (!lobbyLink) {
      return;
    }
    navigator.clipboard.writeText(lobbyLink).then(
      () => {
        console.log('Copied to clipboard successfully!');
      },
      (err) => {
        console.error('Failed to copy: ', err);
      },
    );
  };

  const joinLobby = async () => {
    await lobbyService.joinLobby(token!, {
      lobbyId: joiningLobby!.id,
    });
    setIsPlayerInLobby(true);
    setIsLobbyActive(true);
    setHasJoined(true);
  };

  const leaveLobby = async () => {
    await lobbyService.leaveLobby(token!, {
      lobbyId: joiningLobby!.id,
    });
    setIsPlayerInLobby(false);
    setHasJoined(false);
  };

  const findLobby = async (lobbyOwner: string) => {
    if (token) {
      const activeLobby = await lobbyService.getLobbyByEmail(
        token,
        lobbyOwner,
      );
      if (activeLobby) {
        setLobby(activeLobby);
        setIsLobbyActive(activeLobby.status === 'active');
        lobbyService.setPlayersinLobby(activeLobby.id, activeLobby.players);
        setHasJoined(true);
        const link = await lobbyService.generateLobbyJoinLink(token, activeLobby);
        if (activeLobby?.owner === user.email ) {
          setIsPlayerInLobby(true);
        }
        setLobbyLink(link);
      }
    }
};

  useEffect(() => {
    if (!lobby) {
      return
    }
    if (hasJoined) {
      findLobby(lobby.owner);
     lobbyService.getPlayersInLobby(lobby!.id, (players) => {
      return setPlayersInLobby(players);
    })
  } }, [hasJoined, readyToPlay, lobby?.players.length, isPlayerInLobby]);


  useEffect(() => {
    const listenToSessionUpdates = sessionService.getSessionStatus(
      joiningLobby ? joiningLobby.ownerId : user.id,
      (message) => {
        if (message.status === 'ready') {
          setGamePlay({
            level: message.level.toString(),
            scenario: message.scenario,
            status: GameStatus.Ready,
            gameRemainingSeconds: 0,
          });
         setTimeout(() => {
            goToRoute(AppRoutes.PlayScenario, {
            scenarioId: message.scenario,
            levelSlug: level?.slug,
          });
          }, 400);
        }
      },
    );
    return () => {
      listenToSessionUpdates();
    }
  }, [readyToPlay]);

  useEffect(() => {
    const slackChannel = getSlackChannelForTenant(user.tenant);
    if (!slackChannel) {
      return;
    }
    scenariosService.subscribeToSlackChannel(user.email, slackChannel);
  }, [readyToPlay, user.tenant, user.email]);


  useEffect(() => {
    if (!joiningLobby)  {
      findLobby(user.email);
    } else {
      setLobby(joiningLobby);
    }
  }, [token, user.email , joiningLobby, lobby?.id]);


  const playerList = useMemo(() => {
    if (!playersInLobby) {
      return null;
    }
    return playersInLobby.map((player: LobbyPlayer) => (
      <ul className="lobby-card__player-list" key={player.player}>
        <li>
          <div className="lobby-card__player-list__player-role-container">
            <img
              className="icon"
              src={player.status === "active" ? CheckIcon : CrossIcon}
              alt="status icon"
            />
            <span className="lobby-card__player-list__player-role">
              {player.player === lobby?.owner ? "Lead IM: " : "Secondary IM: "}
            </span>
          </div>
          <div className="lobby-card__player-list__player">
            {player.player}
          </div>
        </li>
      </ul>
    ));
  }, [hasJoined, playersInLobby]);

  return (
    <div className="Card lobby-card">
      <div className="lobby-card__header">
        <ul>
          <li>
          <img className="lobby-card__header-icon"
          src={ClockIcon}
            />
            <h2> {level?.acf.duration} Minutes</h2>
          </li>
          <li >
          <img className="lobby-card__header-icon"
          src={launchIcon}
            />
            <h2> {level?.acf.difficulty}</h2>
          </li>
        </ul>
        {isJoiningExistingLobby && !isPlayerInLobby && (
          <Button
            className="Button  Button--pulse-green Button--small "
            variant="superprimary"
            onClick={joinLobby}
          >
            Join lobby
          </Button>
        )}
         {isJoiningExistingLobby && isPlayerInLobby && (
          <Button
            className="Button Button--warning  Button--small"
            variant="superprimary"
            onClick={leaveLobby}
          >
            Leave lobby
          </Button>
        )}
        {!isJoiningExistingLobby && !isLobbyActive &&  (
          <Button
            className="Button  Button--pulse-green Button--small "
            variant="superprimary"
            onClick={createLobby}
            disabled={!readyToPlay}
          >
            Invite Team
          </Button>
        )}
        {!isJoiningExistingLobby && isLobbyActive && (
          <Button
            className="Button Button--warning  Button--small"
            onClick={closeLobby}
            disabled={!readyToPlay}
          >
            Close existing lobby
          </Button>
        )}
        {lobbyLink && !isJoiningExistingLobby && (
          <div className="textarea-container">
            <textarea
              readOnly
              value={lobbyLink}
              className="textarea"
            ></textarea>
            <button className="copy-button" onClick={copyToClipboard}>
              <img src={CopyIcon} alt="Copy to Clipboard" />
            </button>
          </div>
        )}
        {isLobbyActive && isPlayerInLobby && <ul>{playerList}</ul>}
      </div>
      <div className="lobby-card__content">
        <ul className="lobby-card__content--objectives">
          <p> Objectives:</p>
          <li> Effective Communication</li>
          <li> Team Problem Solving</li>
        </ul>
        <ul className="goals">
          <p> Goals:</p>
          <li >
            <img src={GoalGoldIcon} alt="goals-icon" className="icon" />
             Under 20 Minutes </li>
          <li>
          <img src={GoalSilverIcon} alt="goals-icon" className="icon" />
            Under 25 Minutes </li>
          <li >
          <img src={GoalBronzeIcon} alt="goals-icon" className="icon" />
            Under 30 Minutes </li>
        </ul>
        <div>
        {lobby?.owner === user.email && lobby?.players.length >= 2 && (
          <Button
            className="Button Button--primary Button--small Button--pulse-green"
            variant="superprimary"
            onClick={() => startSession()}
            disabled={!readyToPlay}
          >
            Start drill
          </Button>
        )}
        </div>
      </div>
    </div>
  );
};

export default LobbyCard;

import { WordpressContent } from '../content/types';

export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;
export type OR<T, U> = T | U;

export type Arraize<T> = T | T[];

export type UserInTests = {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  password: string;
};

export type Fixture<T> = (override?: Partial<T>, ...args: any[]) => T;
export type ContentFixture<T> = (
  override?: Partial<WordpressContent<T>>,
  ...args: any[]
) => WordpressContent<T>;
export type Maybe<T> = T | undefined;

export enum PlayerRoles {
  IncidentManager = 'IM',
  SiteReliabilityEngineer = 'SRE',
}
